/****************************************

FOOTER

****************************************/

footer {
	background: color(accent, blue);
	padding: $padding--md 0;

	@media (max-width: bp(nav)) {
		padding: $padding--sm 0;
	}

	p,
	a {
		margin: 0;
		text-align: center;
		font-size: 1.6rem;
		color: color(white);
		text-decoration: none;
	}

	.social {
		justify-content: center;
	}

	.logo {
		height: 85px;
		width: auto;
		margin: 0px auto;

		img {
			height: 100%;
			width: auto;
		}
	}

	.logos {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: bp(nav)) {
			margin-bottom: $padding--sm;
		}

		img {
			height: 70px;
			width: auto;
			margin: 0 5px;
		}
	}

	.column-layout {
		.col {
			@media (max-width: bp(nav)) {
				width: 100% !important;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:nth-of-type(1) {
				width: 165px;
				flex: none;

				@media (max-width: bp(nav)) {
					width: 100%;
				}
			}

			&:nth-of-type(2) {
				width: 100%;

				@media (max-width: bp(nav)) {
					margin: $gap 0;
				}

				.contact {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			&:nth-of-type(3) {
				width: 150px;
			}

			&:nth-of-type(4) {
				width: 100px;
				flex: none;
				align-items: flex-end;

				@media (max-width: bp(nav)) {
					align-items: center;
				}
			}
		}
	}
}

.footer-form {
	.nf-form-cont {
		.nf-form-title {
			h3 {
				@include h2-font-styles;
				text-align: center;
				//color: color(accent);
			}
		}

		.nf-form-wrap {
			width: 100%;

			.nf-before-form-content {
				display: none;
			}

			.nf-form-content {
				padding: 0;
			}

			nf-fields-wrap {
				display: flex;
				flex-wrap: wrap;
			}

			nf-field {
				width: calc((100% - #{$padding--lg * 1}) / 2);
				display: flex;

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				&:nth-of-type(odd) {
					margin-right: $padding--lg;

					@media (max-width: bp(nav)) {
						margin-right: 0;
					}
				}

				.nf-field-container {
					width: 100%;
					margin-bottom: $gap;
				}

				input,
				textarea {
					background: color(grey);
					width: 100%;
					font-family: $bodyFont;
				}

				textarea {
					height: 50px;
					font-family: $bodyFont;
				}

				.submit-container {
					input {
						background: none;
						font-weight: bold;
						color: color(black) !important;
						text-align: left;
						padding: 10px 0;
					}
				}

				.recaptcha-wrap {
					label {
						display: none;
					}
				}
			}
		}
	}
}
