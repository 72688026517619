/****************************************

GLOBAL

****************************************/

/****************************************
Responsive switches
****************************************/
.desktop {
	display: block;

	@media (max-width: bp(nav)) {
		display: none !important;
	}
}

.mobile {
	display: none;

	@media (max-width: bp(nav)) {
		display: block;
	}
}

/****************************************
Structural components
****************************************/
section,
.full {
	@include paddingTopBottom;
	width: 100%;
	position: relative;
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
	width: 100%;

	.video-size-frame {
		height: 0;
		padding-bottom: 56.3%;
		position: relative;
		//border-radius: $borderRadius;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/****************************************
Image crop
****************************************/
.img-crop {
	width: 100%;
	padding-bottom: 66.7%;
	position: relative;
	overflow: hidden;
	display: block;
	//border-radius: $borderRadius;

	&.two-three {
		padding-bottom: 66.7%;
	}

	&.sixteen-nine {
		padding-bottom: 56.3%;
	}

	&.square {
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.contain {
		img {
			object-fit: contain;
		}
	}
}

@mixin imgCropper {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: block;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.img-cropper {
	@include imgCropper;
}

/****************************************
quick show/hide layers
****************************************/
@mixin showLayer {
	position: relative;
	top: auto;
	width: 100%;
	pointer-events: auto;
	opacity: 1;
	z-index: 1;
}

@mixin hideLayer {
	position: absolute;
	width: 100%;
	opacity: 0;
	left: 0;
	pointer-events: none;
	z-index: -5;
}

/****************************************
flex layouts
****************************************/
@mixin flexCenter {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: bp(nav)) {
		flex-direction: column;
	}
}

.flex {
	align-items: center;
	justify-content: space-between;
}

.flex-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.flex-center {
	@include flexCenter;

	.col {
		width: 100%;
		flex: 1 1 auto;
		flex-direction: column;
	}
}

/****************************************
ui basics
****************************************/
@mixin internalNav {
	@include flexCenter;
}

@mixin internalNavLink {
	width: 100%;
	flex: 1 1 auto;
	text-align: center;
}

/****************************************
UTILITY CLASSES
****************************************/
.relative {
	position: relative;
}

/****************************************
BACKGROUNDS
****************************************/
@mixin bgFill() {
	background-size: cover;
	background-position: center center;
}

.bg-fill {
	@include bgFill();
}

.bg-reveal {
	@include bgFill();
	background-attachment: fixed;
}

/****************************************
Enable WYSIWYG Alignment
****************************************/
p img {
	float: left;
	margin: 0 20px 5px 0;

	&.alignright {
		float: right;
		margin: 0 0 5px 20px;
	}

	&.aligncenter {
		float: none;
		margin: 5px auto;
		clear: both;
		display: block;
	}
}
