/****************************************

GLOBAL

****************************************/
.social {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@media (max-width: bp(nav)) {
		margin-bottom: #{$paddingMain/2};
	}

	li {
		&:not(:first-of-type) {
			margin: 0 0 0 #{$gap};
		}

		a {
			width: 30px;
			height: auto;
			display: flex;
			align-items: center;

			img {
				width: 100%;
				height: auto;
				filter: invert(100%);
			}
		}
	}
}

/****************************************
NINJAFORMS REBASE
****************************************/
.form-cont {
	margin: 30px 0;

	.nf-form-content {
		padding: none;
		max-width: auto;
	}

	h3 {
		display: none;
	}

	nf-field {
		width: 100%;
		float: left;
		margin: 0 10px 10px 0;

		.nf-field-container {
			margin-bottom: 0;
		}

		input,
		textarea {
			padding: 10px;
			float: left;
			width: 100%;
			height: auto;
		}
	}

	.nf-field-label {
		//display: none;
	}

	.nf-form-content input[type="button"] {
		background-color: color(accent);

		&:hover {
		}
	}

	.nf-form-fields-required {
		display: none;
	}
}

nobr {
	display: inline-block;
}
