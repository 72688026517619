/****************************************

TYPOGRPAHY

****************************************/

@mixin h1-font-styles {
	font-family: $headlineFont;
	font-weight: 100;
	font-size: 4.8rem;
	line-height: calc(48 / 48);
	color: color(black);

	@include elementSpacing;

	@media (max-width: bp(xl)) {
		//font-size: 7rem;
	}

	@media (max-width: bp(nav)) {
		font-size: 3rem;
	}
}

h1,
.title1 {
	@include h1-font-styles();
}

@mixin h2-font-styles {
	font-family: $headlineFont;
	font-weight: 100;
	font-size: 3rem;
	line-height: calc(50 / 30);
	color: color(black);

	@include elementSpacing;

	@media (max-width: bp(xl)) {
		//font-size: 5rem;
	}

	@media (max-width: bp(nav)) {
		font-size: 2.4rem;
	}
}

h2,
.title2 {
	@include h2-font-styles();
}

@mixin h3-font-styles {
	font-family: $headlineFont;
	font-weight: 100;
	font-size: 2.7rem;
	line-height: calc(36 / 27);
	color: color(black);

	@include elementSpacing;

	@media (max-width: bp(xl)) {
		//font-size: 2.8rem;
	}

	@media (max-width: bp(nav)) {
		font-size: 2.1rem;
		line-height: calc(27 / 21);
	}
}

h3,
.title3 {
	@include h3-font-styles();
}

@mixin h4-font-styles {
	font-family: $headlineFont;
	font-weight: 400;
	font-size: 2.2rem;
	line-height: calc(27 / 18);
	color: color(black);

	@include elementSpacing;

	@media (max-width: bp(xl)) {
		//font-size: 2.4rem;
	}
}

h4,
.title4 {
	@include h4-font-styles();
}

@mixin h5-font-styles {
	font-family: $headlineFont;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: calc(45 / 25);
	color: color(black);
	//debug border: 1px solid magenta !important;

	@include elementSpacing;

	@media (max-width: bp(xl)) {
		font-size: 1.8rem;
	}
}

h5,
.title5 {
	@include h5-font-styles();
}

h6,
.h6,
.title6 {
	font-family: $headlineFont;
}

.small-headline {
	text-transform: uppercase;
	color: color(accent);
	letter-spacing: 0.15rem;
	font-weight: 600;
}

@mixin p-font-styles {
	// "P4" is the default
	font-weight: 400;
	font-size: 1.6rem;
	line-height: calc(27 / 16);

	@media (max-width: bp(xl)) {
		//font-size: 5rem;
	}

	@include elementSpacing;

	sup {
		vertical-align: baseline;
		position: relative;
		top: -0.4em;
	}

	&.disclaimer {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	&.eyebrow {
		font-size: 1rem;
		line-height: 2.1rem;
	}

	&.solo-eyebrow {
		text-transform: uppercase;
		color: color(black);
	}

	a {
		color: color(black);
		text-decoration: underline;
	}
}

a,
.tab-nav,
.accordion-header {
	font-size: 1.8rem;
	line-height: calc(35 / 18);
}

p,
ul,
ol {
	@include p-font-styles;
}

ol {
	padding-left: 1em;
}

@mixin bullets {
	li {
		position: relative;
		padding: 0 0 0 1.5em;

		&:before {
			content: "";
			position: absolute;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: color(black);
			top: 0.8em;
			left: 0;
		}
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}

	&.bullets {
		position: relative;
		@include elementSpacing;
		@include bullets;
	}
}

.strong,
strong {
	font-weight: 800;
}

.italic {
	font-style: italic;
}

.uppercase {
	text-transform: uppercase;
}

.t-accent {
	color: color(accent, blue);
}

.t-body {
	font-family: $bodyFont;
}

.t-center {
	text-align: center;
}

.desc ul {
	@include bullets;
}
