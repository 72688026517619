/****************************************

accordion SECTION

include js: 
acccordianSection()

optional setting:
data-accordion="solo" : show one item only

basic structure: 
<div class="accordion-section" data-accordion="solo">
    <div class="accordion-item" data-accordion-item>
        <div class="accordion-header" data-accordion-header>
            <div class="accordion-icon">
                <span data-accordion-open>+</span>
                <span data-accordion-close>-</span>
            </div>
            accordion A
        </div>
        <div class="accordion-details">
            <p>accordion A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/

.accordion-section {
	.accordion-item {
		.accordion-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row-reverse;
			min-height: 30px;
			cursor: pointer;
			padding: $gap 0;
			border-bottom: 2px solid color(accent);

			p {
				margin-bottom: 0;
				width: 100%;

				@media (max-width: bp(nav)) {
					padding-right: $gap;
				}
			}

			.accordion-icon {
				width: 20px;
				height: 20px;
				flex: none;
				display: block;
				border-top: 1px solid color(accent);
				border-right: 1px solid color(accent);
				transform: translate(-5px, -5px) rotate(135deg);
				position: relative;
				z-index: 99;
				margin: 0 auto;
				transition: all 1s;

				span[data-accordion-open],
				span[data-accordion-close] {
					display: none !important;
				}
			}
		}

		.accordion-details {
			width: 100%;
			max-height: 0px;
			overflow: hidden;
			padding: 0 10px;

			transition: all 0.5s;

			p {
				min-height: 100px;
			}
		}

		&.open {
			.accordion-header .accordion-icon {
				transform: translate(-5px, 5px) rotate(-45deg);
			}

			.accordion-details {
				max-height: 500px;
				padding: 20px 10px;
			}
		}
	}
}
