.page-template-template-about-us {
	.intro-section {
		.column-layout {
			align-items: center;

			.col:nth-of-type(1) {
				.inner {
					padding: #{$padding--lg * 2} #{$padding--lg + $padding--sm} #{$padding--lg * 2} $padding--lg;

					@media (max-width: bp(nav)) {
						padding: $padding--md $padding--sm;
					}
				}
			}
		}
	}

	.video-section {
		.column-layout {
			flex-direction: row-reverse;
			align-items: flex-start;

			@media (max-width: bp(nav)) {
				flex-direction: column;
			}

			.col {
				&:nth-of-type(1) {
					.inner {
						//padding: #{$padding--lg * 2} #{$padding--lg + $padding--sm} #{$padding--lg * 2} $padding--lg;

						@media (max-width: bp(nav)) {
							//padding: $padding--md $padding--sm;
						}
					}
				}

				&:nth-of-type(2) {
					z-index: 9;
					pointer-events: none;
				}
			}
		}
	}

	.staff-section {
		.column-layout {
			.col {
				&:nth-of-type(1) {
					width: 40%;

					@media (max-width: bp(nav)) {
						width: 100%;
					}
				}

				&:nth-of-type(2) {
					width: 60%;
					padding-left: $padding--md;

					@media (max-width: bp(nav)) {
						width: 100%;
						padding-left: 0;
						margin-top: $padding--md;
					}
				}
			}
		}

		h2 {
			text-align: center;
			width: 100%;
		}

		img {
			width: 100%;
		}
	}

	.testimonials-section {
		img {
			width: 100%;
			height: auto;
			z-index: 1;
			position: relative;
			margin-bottom: #{-1 * $padding--md};
		}

		.cont {
			background: color(accent, blue);
			z-index: 9;
			position: relative;
			padding: $padding--lg $padding--lg $padding--md;

			@media (max-width: bp(nav)) {
				padding: $padding--md $padding--sm;
			}

			h2 {
				color: color(white);
				text-transform: uppercase;
				margin-bottom: $padding--md;
			}

			p {
				color: color(white);
			}

			.swiper-slide {
				display: flex;
				flex-direction: column;

				.name {
					align-self: flex-end;
					color: color(white);
				}
			}

			.swiper-cont {
				@media (max-width: bp(nav)) {
					padding-bottom: $padding--md;
				}
			}

			.swiper-pagination {
				position: relative !important;
				bottom: auto;
				padding-left: 0;
				left: auto;

				.swiper-pagination-bullet {
					width: 10px;
					height: 10px;
					margin: 0 5px;
					opacity: 1;

					background-color: rgba(255, 255, 255, 0);
					border: 1px solid color(white);
					transition: all 0.5s;

					&.swiper-pagination-bullet-active {
						opacity: 1;
						background-color: color(white);
					}
				}
			}
		}
	}

	.faqs-section {
		.accordion-header {
			p {
				color: color(black);
			}
		}
	}
}
