main {
	//background: #afb3b3;
	//background-image: linear-gradient(#afb3b3 0, #f3f3f3 50%, #afb3b3 100%);
}

.hero-section {
	padding: 0;

	.top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;

		h1,
		p {
			text-align: center;
			color: color(white);
		}

		p {
			font-family: $bodyFont;
		}

		h1,
		h2,
		h3,
		h4,
		p {
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.85);
		}

		h1 {
			width: auto;
			margin: 0;

			&:after {
				content: "";
				display: block;
				margin: $gap auto 0;
				width: 100%;
				height: 2px;
				background-color: color(accent);
			}
		}
	}

	.top,
	.swiper-slide {
		width: 100%;
		height: 100vh;
		max-height: 1080px;
		overflow: hidden;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			object-fit: cover;
		}
	}

	.bottom {
		padding-top: $padding--lg;

		@media (max-width: bp(nav)) {
			padding-top: $padding--md;
		}

		p {
			//text-align: center;
		}

		.cont--sm {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	.swiper-section {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.cont--lg {
		position: relative;
		z-index: 9;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: $padding--lg;
	}

	&.scroll-arrow .top {
		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border-top: 1px solid color(white);
			border-right: 1px solid color(white);
			transform: translate(0, -65px) rotate(135deg);
			position: relative;
			z-index: 99;
			transition: all 1s;
		}
	}
}

.gallery-section {
	img {
		width: 100%;
		height: auto;
	}
}

.btm-link {
	padding-top: 0;

	.cont {
		width: 100%;
		display: flex;
		align-items: center;

		a {
			color: color(accent);
			flex-wrap: wrap;
			justify-content: center;

			span {
				color: color(accent, blue);
				margin-right: $gap;
			}
		}
	}
}

.orange-offset {
	.column-layout {
		align-items: stretch;
		justify-content: center;

		@media (max-width: bp(nav)) {
			flex-direction: column-reverse;
		}

		.col {
			&:nth-of-type(2) {
				width: 40%;
				z-index: 9;
				margin-left: #{-1 * $padding--sm};
				padding-top: #{1 * $padding--sm};

				@media (max-width: bp(nav)) {
					width: calc(100% - $padding--sm);
					margin-left: 0;
					padding-top: 0;
				}

				img {
					width: 100%;
				}
			}

			&:nth-of-type(1) {
				width: 60%;
				margin-top: #{-1 * $padding--sm};

				@media (max-width: bp(nav)) {
					width: calc(100% - $padding--sm);
					align-self: flex-end;
				}

				.inner {
					padding: $padding--lg #{$padding--lg + $padding--sm} $padding--lg $padding--lg;
					background: color(accent);
					height: 100%;
					margin-bottom: $padding--sm;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					@media (max-width: bp(nav)) {
						padding: $padding--md $padding--sm;
						width: 100%;
						margin-bottom: 0;
					}
				}
			}
		}

		h2 {
			color: color(white);
			text-align: center;
			width: 100%;
		}
	}
}

.blue-offset {
	.column-layout {
		align-items: stretch;
		justify-content: center;

		@media (max-width: bp(nav)) {
			flex-direction: column-reverse;
		}

		.col {
			&:nth-of-type(2) {
				width: 55%;
				z-index: 9;
				margin-right: #{-1 * $padding--sm};
				padding-top: #{1 * $padding--sm};

				@media (max-width: bp(nav)) {
					width: calc(100% - $padding--sm);
					margin-left: 0;
					padding-top: 0;
					margin-top: #{-1 * $padding--sm};
				}

				img {
					width: 100%;
				}
			}

			&:nth-of-type(1) {
				width: 50%;
				margin-top: #{-1 * $padding--sm};

				@media (max-width: bp(nav)) {
					width: calc(100% - $padding--sm);
					align-self: flex-end;
				}

				.inner {
					padding: $padding--lg #{$padding--lg + $padding--sm} $padding--lg $padding--lg;
					background: color(accent, blue);
					height: 100%;
					margin-bottom: $padding--sm;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					@media (max-width: bp(nav)) {
						padding: $padding--md $padding--sm;
						width: 100%;
						margin-bottom: 0;
					}

					p {
						color: color(white);
					}
				}
			}
		}

		h2 {
			color: color(white);
			text-align: center;
			width: 100%;
		}
	}
}

.grey-offset {
	.column-layout {
		align-items: stretch;
		justify-content: center;

		@media (max-width: bp(nav)) {
			flex-direction: column-reverse;
		}

		.col {
			&:nth-of-type(2) {
				width: 60%;
				z-index: 9;
				margin-top: #{-1 * $padding--sm};
				margin-left: #{-1 * $padding--sm};
				padding-bottom: $padding--sm;

				@media (max-width: bp(nav)) {
					margin-left: 0;
					width: calc(100% - $padding--sm);
					padding-bottom: 0;
					margin-bottom: #{-1 * $padding--sm};
					align-self: flex-end;
				}

				img {
					width: 100%;
				}
			}

			&:nth-of-type(1) {
				width: 40%;

				@media (max-width: bp(nav)) {
					width: calc(100% - $padding--sm);
				}

				.inner {
					padding: $padding--lg #{$padding--md + $padding--sm} $padding--lg $padding--md;
					background: color(accent, blue);
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					@media (max-width: bp(nav)) {
						padding: $padding--md $padding--sm;
					}

					p {
						color: color(white);
					}
				}
			}
		}

		h2 {
			color: color(white);
			text-align: center;
			width: 100%;
			text-transform: uppercase;
		}
	}
}

.nf-form-cont {
	width: 100%;

	h3 {
		@include h2-font-styles;
	}

	.nf-before-form-content {
		display: none;
	}

	.nf-form-content {
		padding: 0;
	}

	nf-field {
		.nf-field-container {
			width: 100%;
			margin-bottom: $gap;
		}

		input,
		textarea {
			border: 1px solid color(grey) !important;
			color: color(white);
			background: color(white) !important;
			width: 100%;
			text-transform: uppercase;
			font-family: $bodyFont;
		}

		textarea {
			height: 100px !important;
			font-family: $bodyFont;
		}

		.submit-container {
			cursor: pointer;

			.nf-field-element {
				display: flex;
				justify-content: center;
			}

			input {
				border: 1px solid color(grey);
				color: color(grey);
				background: none;
				padding: 5px 10px;
				height: auto;
				margin: 0 auto;
				display: block;
			}
		}
	}
}

.main-sub {
	display: flex;
	flex-direction: column;
	width: 100%;

	.main-img {
		width: 90%;
		height: auto;
	}

	.sub-img {
		width: 50%;
		height: auto;
		align-self: flex-end;
		margin-top: #{-1 * $padding--md};
	}
}
