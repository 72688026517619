/****************************************
REBASE
****************************************/
.debug-title {
	display: none;
}

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	font-size: 10px;
	color: color(black);
	font-family: $bodyFont;
	background-color: #fff;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}
}

p,
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	color: color(black);
}

main {
	//min-height: calc(100vh - 200px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

section {
	img {
		max-width: 100%;
		height: auto;
	}
}
