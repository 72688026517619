.filters {
	flex-direction: column;
	display: flex;
	width: 100%;
	margin-top: $padding--md;

	.col {
		width: 100%;
	}

	.rw {
		display: flex;
		width: 100%;
		align-items: center;

		@media (max-width: bp(nav)) {
			flex-direction: column;
		}

		&:not(:last-of-type) {
			margin-bottom: $padding--md;
		}
	}
}

.filter {
	@include p-font-styles();
	font-size: 1.4rem;
	text-transform: uppercase;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	position: relative;
	border: none;
	margin: 0 0 0 0;
	padding: 0;

	@media (max-width: bp(nav)) {
		margin-bottom: $gap;
	}

	label {
		height: auto;
		line-height: 1;
		margin-right: $padding--sm;

		@media (max-width: bp(nav)) {
			min-width: 75px;
			text-align: right;
		}
	}

	p.dropdown {
		display: flex;
		width: 100% !important;
		justify-content: space-between;
		align-items: center;
		padding: 5px 15px;
		margin: 0;
		color: color(accent, blue);
		cursor: pointer;
		border: 1px solid color(accent, blue);

		&:after {
			content: "";
			width: 10px;
			height: 10px;
			border-right: 1px solid color(accent, blue);
			border-top: 1px solid color(accent, blue);
			margin-left: $padding--sm;
			transform: translate(0, -2px) rotate(135deg);
			display: block;
			transition: transform $animationDuration;
		}
	}

	&.active p.dropdown:after {
		transform: translate(0, 3px) rotate(-45deg);
	}

	fieldset {
		border: none;
		padding: 0;
		position: relative;
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		-webkit-appearance: none;
		border: none;
	}

	&.overlay-filter {
		position: relative;
		z-index: 99;

		.overlay {
			position: absolute;
			top: 80px;
			left: 0;
			min-width: 225px;
			padding: 10px;
			border: 1px solid color(black);
			background: color(white);
			z-index: 9;
			display: flex;
			justify-content: center;
			opacity: 0;
			pointer-events: none;
			transition: opacity $animationDuration;

			@media (max-width: bp(nav)) {
				position: relative;
				max-height: 0;
				padding: 0px 10px;
				top: auto;
				left: auto;
				overflow: hidden;
				transition: max-width $animationDuration, opacity $animationDuration;
			}

			.filter {
				margin: 0;
				width: 100%;
				padding: 0 10px;
			}

			.filter:nth-of-type(2) {
				border-left: 1px solid color(black);
			}
		}

		&.active .overlay {
			opacity: 1;
			pointer-events: auto;

			@media (max-width: bp(nav)) {
				max-height: 900px;
				padding: 10px;
			}
		}
	}

	&.home-styles {
		flex-direction: column;
		align-items: flex-start;

		@media (max-width: bp(nav)) {
			align-items: center;
			margin-bottom: $padding--sm;
		}

		> label {
			display: none;
		}

		.filter {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			label:not(:last-of-type) {
				margin-bottom: $gap;
			}
		}
	}

	.cb {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked {
				~ .checkmark {
					background-color: color(accent, blue);
				}

				~ .checkmark:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: relative;
			height: 20px;
			width: 20px;
			margin-right: 10px;
			border-radius: 50%;
			background-color: none;
			border: 1px solid color(accent, blue);
			transition: background-color $animationDuration;

			&:after {
				content: "";
				position: absolute;
				display: none;
				left: 5px;
				top: 1px;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	&.sqft {
		.flex {
			display: flex;
			flex-direction: column;
		}

		label {
			text-align: center;
			margin-right: 0;
			margin-bottom: $padding--sm;
			width: 100%;
		}
	}
}

.range_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;

	@media (max-width: bp(nav)) {
		margin: #{$paddingMain/2} 0 0;
	}

	.sliders_control {
		position: relative;
		width: 100%;
	}

	.scale {
		display: flex;
		justify-content: space-between;
		margin-top: $padding--sm;

		span {
			width: auto;
		}
	}

	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		pointer-events: all;
		width: 15px;
		height: 15px;
		background-color: color(accent, blue);
		border-radius: 50%;
		border: none;
		cursor: pointer;

		&:hover {
		}
	}

	input[type="range"]::-moz-range-thumb {
		-webkit-appearance: none;
		pointer-events: all;
		width: 24px;
		height: 24px;
		background-color: #fff;
		border-radius: 50%;
		cursor: pointer;
	}

	input[type="range"]::-webkit-slider-thumb:active {
		box-shadow: inset 0 0 3px color(accent, blue), 0 0 9px color(accent, blue);
	}

	input[type="range"] {
		-webkit-appearance: none;
		appearance: none;
		height: 2px;
		width: 100%;
		position: absolute;
		background-color: #c6c6c6;
		pointer-events: none;
	}

	#fromSlider {
		height: 0;
		z-index: 1;
	}

	.min,
	.max {
		position: absolute;
		top: 10px;
		font-size: 12px;
		width: 50px;
		transform: translate((7.5px - 25px), 0);
		text-align: center;
	}

	.max {
		transform: translate((-7.5px - 25px), 0);
	}
}
