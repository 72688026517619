.page-template-template-custom {
	.intro-section {
		padding-top: $padding--md;

		.column-layout {
			align-items: stretch;
			justify-content: space-between;

			h2 {
				color: color(accent, blue);
			}

			.col {
				width: 50%;

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				&:nth-of-type(1) {
					border-right: 1px solid color(accent, blue);
					padding-right: $padding--md;

					@media (max-width: bp(nav)) {
						border-right: none;
						border-bottom: 1px solid color(accent, blue);
						padding-right: 0;
						padding-bottom: $padding--md;
						margin-bottom: $padding--md;
					}
				}

				&:nth-of-type(2) {
					padding-left: $padding--md;

					@media (max-width: bp(nav)) {
						padding-left: 0;
					}
				}
			}
		}
	}

	.gallery-section {
		.swiper-section {
			margin-top: $padding--md;
		}
	}
}
