.page-template-homebuilder {
	.map-section {
		padding: 0;

		[data-categories] {
			display: flex;
			align-items: center;
			justify-content: space-around;
			height: auto;
			padding: $padding--sm 0;
		}

		[data-map],
		[data-pois] {
			height: calc(100vh - 400px);
		}
	}
}
