/****************************************

Takeover Nav

****************************************/

header {
	&.active {
		nav {
			width: 100vw;
			right: 0;
		}
	}

	&.scroll nav {
		background: rgba(255, 255, 255, 0.95) !important;
	}

	nav {
		width: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		right: 0;
		padding-top: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.95) !important;
		transition: width $animationDuration, padding $animationDuration, right #{$animationDuration * 1.5};
		z-index: 1;

		//@media (max-width: $mobileNav) {
		width: 100vw;
		height: 100vh;
		right: -100vw;
		background: color(white);
		//padding-top: 110px;
		//}

		@media (max-width: bp(nav)) {
			padding-top: 50px;
		}

		.menu {
			width: 100vw;
			//height: 100vh;
			max-height: calc(100vh - 100px);
			overflow: scroll;
			padding: 5px 10px 5px 175px;
			display: flex;
			flex-direction: row;

			//@media (max-width: $mobileNav) {
			flex-direction: column;
			padding: 5px 10px;
			max-width: 500px;
			margin: 0 auto;
			//}

			li {
				margin: 0;

				&:not(:last-of-type) {
					margin-bottom: 10px;
				}

				a {
					@include h2-font-styles();

					margin: 0;
					color: color(black);
					text-decoration: none;
					font-family: $headlineFont;
					padding: 12px 10px;
					min-height: 59px;
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					width: auto;
					position: relative;
					font-weight: 100;
					transition: color $animationDuration;

					min-height: inherit;
					padding: 5px 10px;

					&.active {
						color: color(accent);
					}
				}

				&.current_page_item {
					a {
						color: color(accent);
					}

					ul {
						li a {
							color: color(black);
						}

						li.current_page_item {
							a {
								color: color(accent);
							}
						}
					}
				}

				&.menu-item-has-children {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;

					a {
						order: -1;
					}

					.toggle-menu {
						content: "";
						width: 15px;
						height: 15px;
						border-right: 2px solid color(black);
						border-top: 2px solid color(black);
						transform: rotate(135deg) translate(-5px, 0);
						transition: transform $animationDuration;
						display: block;
						margin-left: 15px;
						cursor: pointer;
					}

					ul {
						width: 100%;
						max-height: 0;
						overflow: hidden;
						transition: max-height $animationDuration;
					}

					&.active {
						> .toggle-menu {
							transform: rotate(-45deg) translate(0px, 10px);
						}

						ul {
							max-height: 1000px;
						}
					}
				}
			}
		}
	}

	.highlight-menu {
		display: flex;
		align-items: center;

		@media (max-width: bp(nav)) {
			width: 100%;
			flex-direction: column;
			display: none;

			.menu-highlight-menu-container {
				width: 100%;
			}
		}

		.current {
			display: none;
			text-align: right;
			text-transform: uppercase;
			padding: 0;
			margin: 0;
			text-decoration: none;
			letter-spacing: 1.5px;

			@media (max-width: bp(nav)) {
				//	display: block;
				//	width: 100%;
			}
		}

		ul {
			display: flex;
			align-items: center;

			@media (max-width: bp(nav)) {
				flex-direction: column;
				width: 100%;
				align-items: flex-end;
				max-height: 0;
				overflow: hidden;
				transition: max-height 0.5s;
			}

			a {
				text-transform: uppercase;
				padding: 0 15px;
				text-decoration: none;
				letter-spacing: 1.5px;

				@media (max-width: bp(nav)) {
					width: 100%;
					text-align: right;
					padding-right: 0;
				}
			}

			li.current_page_item,
			li.current-page-ancestor {
				a {
					text-decoration: underline;
				}

				ul {
					li a {
						text-decoration: none;
					}

					li.current_page_item {
						a {
							text-decoration: underline;
						}
					}
				}
			}

			li {
				&.menu-item-has-children {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					@media (max-width: 1440px) {
						position: inherit;
					}

					ul {
						display: none !important;
						width: 200px;
						position: absolute;
						top: 25px;
						left: -10px;
						width: 280px;
						background: white;
						padding: 0 10px;
						max-height: 0;
						overflow: hidden;
						transition: max-height $animationDuration, padding $animationDuration;

						@media (max-width: 1440px) {
							left: auto;
							right: 0;
							top: 45px;
						}
					}

					&.active,
					&:hover {
						> li:after {
							transform: rotate(-45deg) translate(0px, 10px);
						}

						ul {
							max-height: 1000px;
							padding: 10px;
						}
					}
				}
			}
		}

		&.active {
			ul {
				max-height: 500px;
			}
		}
	}
}

body.scroll {
	//padding-top: 107px;

	@media (max-width: bp(nav)) {
		padding-top: 0;
	}

	header {
		backdrop-filter: blur(5px);
	}
}

.header-spacing {
	width: 100%;
	height: 59px;

	@media (max-width: bp(nav)) {
		height: 60px;
	}
}
