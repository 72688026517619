/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
	padding: 10px 20px;
	font-size: 1.4rem;
	line-height: 1;
	font-weight: 400;
	color: color(white);
	border: 1px solid color(accent);
	background-color: color(accent);
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	display: table;
	cursor: pointer;
	transition: border $animationDuration, background-color $animationDuration, color $animationDuration;

	&:hover {
		border: 1px solid color(accent, blue);
		background-color: color(accent, blue);
		color: color(white);
	}
}

.btn {
	@include btnStyle;

	&.center {
		margin: 0 auto;
	}

	&.secondary {
		border: 1px solid color(accent, green);
		color: color(white);
		background-color: color(accent, green);

		&:hover {
			border: 1px solid color(accent);
			background-color: color(accent);
			color: color(white);
		}
	}

	&.lg {
		display: flex;
		align-items: center;
		@include h2-font-styles;
		font-family: $bodyFont;
		background: none;
		border: none;
		padding: 0;
		margin: 0 auto;
		text-transform: inherit;
		color: color(white);

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			flex: none;
			display: block;
			border-top: 1px solid color(white);
			border-right: 1px solid color(white);
			transform: translate(0, 3px) rotate(45deg);
			position: relative;
			z-index: 99;
			transition: all 1s;
		}

		&:hover {
			&:after {
				transform: translate(10px, 3px) rotate(45deg);
			}
		}

		&.accent {
			color: color(accent);

			&:after {
				border-color: color(accent);
			}
		}
	}
}

.links {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	*:not(:last-of-type) {
		margin-right: 30px;
	}
}
