// SOLO
.floorplans-template-default,
.quickdeliveries-template-default {
	.intro-section {
		margin-top: $padding--md;

		.column-layout {
			align-items: stretch;
			justify-content: center;

			> .col {
				&:nth-of-type(1) {
					width: 60%;
					z-index: 9;
					margin-right: #{-1 * $padding--sm};
					justify-content: flex-start;

					@media (max-width: bp(nav)) {
						width: calc(100% - $padding--sm);
						margin-right: 0;
					}

					img {
						width: 100%;
					}
				}

				&:nth-of-type(2) {
					width: 40%;

					@media (max-width: bp(nav)) {
						width: calc(100% - $padding--sm);
						margin-top: #{-1 * $padding--sm};
						align-self: flex-end;
					}

					.inner {
						padding: $padding--lg $padding--md $padding--lg #{$padding--md + $padding--sm};
						margin-top: #{1 * $padding--sm};
						background: color(accent, blue);
						height: 100%;
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						@media (max-width: bp(nav)) {
							padding: $padding--md $padding--md;
							margin-top: 0;
						}

						p {
							color: color(white);
						}
					}
				}
			}

			h1 {
				color: color(white);
				text-align: center;
				width: 100%;
			}
		}

		.stats {
			max-width: 80%;
			margin: 0 auto;
			border-bottom: 1px solid color(white);
			padding-bottom: $gap;
			margin-top: $padding--sm;
			margin-bottom: $padding--sm;
			flex-direction: row !important;

			.col {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: color(white);
				text-transform: uppercase;
				line-height: 1.2;
				font-size: 1.4rem;

				span {
					color: color(white);
					font-weight: bold;
				}
			}
		}

		p {
			text-align: center;
			text-transform: uppercase;
			margin: 0;

			&:last-of-type {
				margin-bottom: $gap;
			}
		}

		.desc p {
			text-transform: inherit;
			text-align: left;
		}
	}

	.floorplans-section {
		background: color(white);

		.column-layout {
			.col {
				&:nth-of-type(1) {
					width: 200px;
					flex: none;
					justify-content: flex-start;

					@media (max-width: bp(nav)) {
						width: 100%;
					}
				}

				.tab-nav {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;

					@media (max-width: bp(nav)) {
						margin-bottom: $padding--sm;
					}

					.tab-link {
						font-family: $bodyFont;
						text-transform: uppercase;
						color: color(black);
						justify-content: flex-start;
						text-align: left;
						padding: 0;

						&.active {
							text-decoration: none;
							color: color(accent, blue);
						}
					}
				}

				&:nth-of-type(2) {
					width: 100%;
					padding-left: $padding--md;

					@media (max-width: bp(nav)) {
						padding-left: 0;
					}

					a[data-fancybox] {
						display: block;
						position: relative;
						img {
							position: Relative;
							z-index: 0;
						}

						&:before {
							content: "Click to View Larger";
							position: absolute;
							font-size: 36px;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							z-index: 1;
							color: #fff;
							text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: opacity 0.5s;
						}

						&:hover:before {
							opacity: 1;
						}
					}

					.tab-item img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}

	.gallery-section {
		.swiper-section {
			margin-top: $padding--md;
		}

		.swiper-cont.thumb-swiper .swiper-slide {
			cursor: pointer;
		}

		.swiper-button-next {
			svg {
				transform: rotate(180deg) translate(-15px, 0);
			}
		}
	}
}

// INDEX
.page-template-template-floor-plans-index {
	.hero-section {
		background: color(white);
	}

	.filters-top {
		background: color(white);
		padding: $padding--md 0;
		margin-bottom: $padding--md;

		.filters {
			margin-top: 0;
		}
	}

	.fp-filters {
		//margin-bottom: $padding--lg;

		.run-search {
			display: none;
		}
	}

	.all-floor-plans {
		padding-top: 0;

		.count {
			display: none;

			&.active {
				display: block;
			}
		}

		.card.hide {
			display: none;
		}
	}
}

// card
.card.fp-card {
	.stats {
		flex-direction: column;
		font-size: 1.4rem;
	}
}
