/****************************************

VARIABLES

****************************************/
@import "_functions";

// MSC
$animationDuration: 0.5s;
$borderRadius: 10px;

//fonts
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
$headlineFont: "EB Garamond", serif;
$bodyFont: "Montserrat", sans-serif;

//break points
$breakPoints: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	nav: 992px,
	tablet: 1024px,
	sm-desktop: 1200px,
	lg-desktop: 1400px,
);

$mobile: bp(nav);

//Spacing
$padding--xs: 1rem;
$padding--sm: 2rem;
$padding--md: 4rem;
$padding--lg: 8rem;
$paddingMain: $padding--sm;
$paddingMainMobile: 2rem;
$gap: 1rem;

// Padding/Spacing
$elementSpacingDesktop: 2rem;
@mixin elementSpacing {
	margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
	padding: $padding--lg;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile;
	}
}

@mixin paddingTopBottom {
	padding: $padding--lg 0;

	@media (max-width: bp(nav)) {
		padding: #{$padding--md} 0;
	}
}

//colors
$colors: (
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0.25),
	),
	black: (
		base: #000,
	),
	grey: (
		base: #b5bcba,
		copy: #767676,
	),
	accent: (
		base: #d5b868,
		blue: #5b809b,
	),
);

$grad: linear-gradient(90deg, #0e4486 0%, #246fc7 100%);
