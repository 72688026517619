.page-template-template-semi-custom {
	.hero-section {
		.btn {
			margin: $padding--sm auto 0;
		}
	}

	.intro-section {
		padding-top: 0;
		margin-top: #{-1 * $padding--md};
		z-index: 9;

		@media (max-width: bp(nav)) {
			margin-top: 0;
		}

		.column-layout {
			align-items: stretch;
			justify-content: center;

			.col {
				&:nth-of-type(1) {
					width: 60%;
					z-index: 9;
					padding-top: $padding--lg;

					@media (max-width: bp(nav)) {
						padding-top: $padding--md;
						width: calc(100% - $padding--sm);
					}

					h2 {
						color: color(white);
						font-family: $bodyFont;
					}

					h2,
					p {
						width: calc(100% - $padding--lg);
						margin-left: $padding--md;

						@media (max-width: bp(nav)) {
							padding: 0 $padding--sm;
							margin-left: 0;
							width: 100%;
						}
					}

					img {
						margin-top: $padding--md;
					}
				}

				&:nth-of-type(2) {
					width: 40%;
					padding-top: $padding--sm;
					margin-left: #{-1 * $padding--sm};

					@media (max-width: bp(nav)) {
						width: calc(100% - $padding--sm);
						margin-left: 0;
						padding-top: 0;
						align-self: flex-end;
						margin-top: #{-1 * $padding--sm};
					}

					.inner {
						background: color(accent);
						padding: $padding--md $padding--md $padding--md #{$padding--md + $padding--sm};
						height: 100%;
						margin-bottom: $padding--sm;

						@media (max-width: bp(nav)) {
							padding: $padding--md $padding--sm;
						}
					}

					p.title {
						color: color(white);
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.floor-plans-section {
		background: color(white);
	}
}
