.page-template-template-process {
	.steps-sections {
		padding: 0;

		.full {
			&:not(:last-of-type) {
				border-bottom: 1px solid color(accent, blue);
			}

			h2 {
				display: flex;
				align-items: center;
				justify-content: center;
				color: color(accent, blue);
				margin-bottom: $padding--md;

				img {
					margin-right: $padding--sm;
					width: 50px;
					height: auto;
				}
			}

			.column-layout {
				.col {
					&:nth-of-type(1) {
						width: 40%;

						@media (max-width: bp(nav)) {
							width: 100%;
						}
					}

					&:nth-of-type(2) {
						width: 60%;
						padding-left: $padding--md;

						@media (max-width: bp(nav)) {
							width: 100%;
							padding-left: 0;
							margin-top: $padding--md;
						}
					}
				}
			}

			&:nth-of-type(even) {
				h2 {
					color: color(accent);
				}

				.column-layout {
					flex-direction: row-reverse;

					@media (max-width: bp(nav)) {
						flex-direction: column;
					}

					.col {
						&:nth-of-type(1) {
							width: 40%;

							@media (max-width: bp(nav)) {
								width: 100%;
							}
						}

						&:nth-of-type(2) {
							width: 60%;
							padding-right: $padding--md;
							padding-left: 0;

							@media (max-width: bp(nav)) {
								width: 100%;
								padding-right: 0;
								margin-top: $padding--md;
							}
						}
					}
				}

				.main-sub {
					.main-img {
						align-self: flex-end;
					}

					.sub-img {
						align-self: flex-start;
					}
				}
			}
		}
	}
}
