/****************************************

FUNCTIONS

****************************************/
/****************************************

VARIABLES

****************************************/
/****************************************

FUNCTIONS

****************************************/
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/****************************************
REBASE
****************************************/
.debug-title {
  display: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  color: #000;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section img {
  max-width: 100%;
  height: auto;
}

/****************************************

GLOBAL

****************************************/
/****************************************
Responsive switches
****************************************/
.desktop {
  display: block;
}
@media (max-width: 992px) {
  .desktop {
    display: none !important;
  }
}

.mobile {
  display: none;
}
@media (max-width: 992px) {
  .mobile {
    display: block;
  }
}

/****************************************
Structural components
****************************************/
section,
.full {
  padding: 8rem 0;
  width: 100%;
  position: relative;
}
@media (max-width: 992px) {
  section,
  .full {
    padding: 4rem 0;
  }
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
  width: 100%;
}
.responsive-video .video-size-frame {
  height: 0;
  padding-bottom: 56.3%;
  position: relative;
  overflow: hidden;
}
.responsive-video .video-size-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/****************************************
Image crop
****************************************/
.img-crop {
  width: 100%;
  padding-bottom: 66.7%;
  position: relative;
  overflow: hidden;
  display: block;
}
.img-crop.two-three {
  padding-bottom: 66.7%;
}
.img-crop.sixteen-nine {
  padding-bottom: 56.3%;
}
.img-crop.square {
  padding-bottom: 100%;
}
.img-crop img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-crop.contain img {
  object-fit: contain;
}

.img-cropper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
}
.img-cropper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/****************************************
quick show/hide layers
****************************************/
/****************************************
flex layouts
****************************************/
.flex {
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .flex-center {
    flex-direction: column;
  }
}
.flex-center .col {
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
}

/****************************************
ui basics
****************************************/
/****************************************
UTILITY CLASSES
****************************************/
.relative {
  position: relative;
}

/****************************************
BACKGROUNDS
****************************************/
.bg-fill {
  background-size: cover;
  background-position: center center;
}

.bg-reveal {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

/****************************************
Enable WYSIWYG Alignment
****************************************/
p img {
  float: left;
  margin: 0 20px 5px 0;
}
p img.alignright {
  float: right;
  margin: 0 0 5px 20px;
}
p img.aligncenter {
  float: none;
  margin: 5px auto;
  clear: both;
  display: block;
}

/****************************************

GLOBAL

****************************************/
.social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .social {
    margin-bottom: 1rem;
  }
}
.social li:not(:first-of-type) {
  margin: 0 0 0 1rem;
}
.social li a {
  width: 30px;
  height: auto;
  display: flex;
  align-items: center;
}
.social li a img {
  width: 100%;
  height: auto;
  filter: invert(100%);
}

/****************************************
NINJAFORMS REBASE
****************************************/
.form-cont {
  margin: 30px 0;
}
.form-cont .nf-form-content {
  padding: none;
  max-width: auto;
}
.form-cont h3 {
  display: none;
}
.form-cont nf-field {
  width: 100%;
  float: left;
  margin: 0 10px 10px 0;
}
.form-cont nf-field .nf-field-container {
  margin-bottom: 0;
}
.form-cont nf-field input,
.form-cont nf-field textarea {
  padding: 10px;
  float: left;
  width: 100%;
  height: auto;
}
.form-cont .nf-form-content input[type=button] {
  background-color: #d5b868;
}
.form-cont .nf-form-fields-required {
  display: none;
}

nobr {
  display: inline-block;
}

/****************************************

TYPOGRPAHY

****************************************/
h1,
.title1 {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 4.8rem;
  line-height: 1;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h1,
  .title1 {
    font-size: 3rem;
  }
}

h2,
.title2 {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h2,
  .title2 {
    font-size: 2.4rem;
  }
}

h3,
.title3 {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 2.7rem;
  line-height: 1.3333333333;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h3,
  .title3 {
    font-size: 2.1rem;
    line-height: 1.2857142857;
  }
}

h4,
.title4 {
  font-family: "EB Garamond", serif;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #000;
  margin-bottom: 2rem;
}
h5,
.title5 {
  font-family: "EB Garamond", serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  h5,
  .title5 {
    font-size: 1.8rem;
  }
}

h6,
.h6,
.title6 {
  font-family: "EB Garamond", serif;
}

.small-headline {
  text-transform: uppercase;
  color: #d5b868;
  letter-spacing: 0.15rem;
  font-weight: 600;
}

a,
.tab-nav,
.accordion-header {
  font-size: 1.8rem;
  line-height: 1.9444444444;
}

p,
ul,
ol {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6875;
  margin-bottom: 2rem;
}
p sup,
ul sup,
ol sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
p.disclaimer,
ul.disclaimer,
ol.disclaimer {
  font-size: 1.4rem;
  line-height: 1.6rem;
}
p.eyebrow,
ul.eyebrow,
ol.eyebrow {
  font-size: 1rem;
  line-height: 2.1rem;
}
p.solo-eyebrow,
ul.solo-eyebrow,
ol.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
p a,
ul a,
ol a {
  color: #000;
  text-decoration: underline;
}

ol {
  padding-left: 1em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}
ul.bullets {
  position: relative;
  margin-bottom: 2rem;
}
ul.bullets li {
  position: relative;
  padding: 0 0 0 1.5em;
}
ul.bullets li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  top: 0.8em;
  left: 0;
}

.strong,
strong {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.t-accent {
  color: #5b809b;
}

.t-body {
  font-family: "Montserrat", sans-serif;
}

.t-center {
  text-align: center;
}

.desc ul li {
  position: relative;
  padding: 0 0 0 1.5em;
}
.desc ul li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  top: 0.8em;
  left: 0;
}

.hero-section {
  padding: 0;
}
.hero-section .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.hero-section .top h1,
.hero-section .top p {
  text-align: center;
  color: #fff;
}
.hero-section .top p {
  font-family: "Montserrat", sans-serif;
}
.hero-section .top h1,
.hero-section .top h2,
.hero-section .top h3,
.hero-section .top h4,
.hero-section .top p {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.85);
}
.hero-section .top h1 {
  width: auto;
  margin: 0;
}
.hero-section .top h1:after {
  content: "";
  display: block;
  margin: 1rem auto 0;
  width: 100%;
  height: 2px;
  background-color: #d5b868;
}
.hero-section .top,
.hero-section .swiper-slide {
  width: 100%;
  height: 100vh;
  max-height: 1080px;
  overflow: hidden;
  position: relative;
}
.hero-section .top img,
.hero-section .swiper-slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}
.hero-section .bottom {
  padding-top: 8rem;
}
@media (max-width: 992px) {
  .hero-section .bottom {
    padding-top: 4rem;
  }
}
.hero-section .bottom .cont--sm {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.hero-section .swiper-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.hero-section .cont--lg {
  position: relative;
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8rem;
}
.hero-section.scroll-arrow .top:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: translate(0, -65px) rotate(135deg);
  position: relative;
  z-index: 99;
  transition: all 1s;
}

.gallery-section img {
  width: 100%;
  height: auto;
}

.btm-link {
  padding-top: 0;
}
.btm-link .cont {
  width: 100%;
  display: flex;
  align-items: center;
}
.btm-link .cont a {
  color: #d5b868;
  flex-wrap: wrap;
  justify-content: center;
}
.btm-link .cont a span {
  color: #5b809b;
  margin-right: 1rem;
}

.orange-offset .column-layout {
  align-items: stretch;
  justify-content: center;
}
@media (max-width: 992px) {
  .orange-offset .column-layout {
    flex-direction: column-reverse;
  }
}
.orange-offset .column-layout .col:nth-of-type(2) {
  width: 40%;
  z-index: 9;
  margin-left: -2rem;
  padding-top: 2rem;
}
@media (max-width: 992px) {
  .orange-offset .column-layout .col:nth-of-type(2) {
    width: calc(100% - 2rem);
    margin-left: 0;
    padding-top: 0;
  }
}
.orange-offset .column-layout .col:nth-of-type(2) img {
  width: 100%;
}
.orange-offset .column-layout .col:nth-of-type(1) {
  width: 60%;
  margin-top: -2rem;
}
@media (max-width: 992px) {
  .orange-offset .column-layout .col:nth-of-type(1) {
    width: calc(100% - 2rem);
    align-self: flex-end;
  }
}
.orange-offset .column-layout .col:nth-of-type(1) .inner {
  padding: 8rem 10rem 8rem 8rem;
  background: #d5b868;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .orange-offset .column-layout .col:nth-of-type(1) .inner {
    padding: 4rem 2rem;
    width: 100%;
    margin-bottom: 0;
  }
}
.orange-offset .column-layout h2 {
  color: #fff;
  text-align: center;
  width: 100%;
}

.blue-offset .column-layout {
  align-items: stretch;
  justify-content: center;
}
@media (max-width: 992px) {
  .blue-offset .column-layout {
    flex-direction: column-reverse;
  }
}
.blue-offset .column-layout .col:nth-of-type(2) {
  width: 55%;
  z-index: 9;
  margin-right: -2rem;
  padding-top: 2rem;
}
@media (max-width: 992px) {
  .blue-offset .column-layout .col:nth-of-type(2) {
    width: calc(100% - 2rem);
    margin-left: 0;
    padding-top: 0;
    margin-top: -2rem;
  }
}
.blue-offset .column-layout .col:nth-of-type(2) img {
  width: 100%;
}
.blue-offset .column-layout .col:nth-of-type(1) {
  width: 50%;
  margin-top: -2rem;
}
@media (max-width: 992px) {
  .blue-offset .column-layout .col:nth-of-type(1) {
    width: calc(100% - 2rem);
    align-self: flex-end;
  }
}
.blue-offset .column-layout .col:nth-of-type(1) .inner {
  padding: 8rem 10rem 8rem 8rem;
  background: #5b809b;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .blue-offset .column-layout .col:nth-of-type(1) .inner {
    padding: 4rem 2rem;
    width: 100%;
    margin-bottom: 0;
  }
}
.blue-offset .column-layout .col:nth-of-type(1) .inner p {
  color: #fff;
}
.blue-offset .column-layout h2 {
  color: #fff;
  text-align: center;
  width: 100%;
}

.grey-offset .column-layout {
  align-items: stretch;
  justify-content: center;
}
@media (max-width: 992px) {
  .grey-offset .column-layout {
    flex-direction: column-reverse;
  }
}
.grey-offset .column-layout .col:nth-of-type(2) {
  width: 60%;
  z-index: 9;
  margin-top: -2rem;
  margin-left: -2rem;
  padding-bottom: 2rem;
}
@media (max-width: 992px) {
  .grey-offset .column-layout .col:nth-of-type(2) {
    margin-left: 0;
    width: calc(100% - 2rem);
    padding-bottom: 0;
    margin-bottom: -2rem;
    align-self: flex-end;
  }
}
.grey-offset .column-layout .col:nth-of-type(2) img {
  width: 100%;
}
.grey-offset .column-layout .col:nth-of-type(1) {
  width: 40%;
}
@media (max-width: 992px) {
  .grey-offset .column-layout .col:nth-of-type(1) {
    width: calc(100% - 2rem);
  }
}
.grey-offset .column-layout .col:nth-of-type(1) .inner {
  padding: 8rem 6rem 8rem 4rem;
  background: #5b809b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .grey-offset .column-layout .col:nth-of-type(1) .inner {
    padding: 4rem 2rem;
  }
}
.grey-offset .column-layout .col:nth-of-type(1) .inner p {
  color: #fff;
}
.grey-offset .column-layout h2 {
  color: #fff;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.nf-form-cont {
  width: 100%;
}
.nf-form-cont h3 {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  .nf-form-cont h3 {
    font-size: 2.4rem;
  }
}
.nf-form-cont .nf-before-form-content {
  display: none;
}
.nf-form-cont .nf-form-content {
  padding: 0;
}
.nf-form-cont nf-field .nf-field-container {
  width: 100%;
  margin-bottom: 1rem;
}
.nf-form-cont nf-field input,
.nf-form-cont nf-field textarea {
  border: 1px solid #b5bcba !important;
  color: #fff;
  background: #fff !important;
  width: 100%;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.nf-form-cont nf-field textarea {
  height: 100px !important;
  font-family: "Montserrat", sans-serif;
}
.nf-form-cont nf-field .submit-container {
  cursor: pointer;
}
.nf-form-cont nf-field .submit-container .nf-field-element {
  display: flex;
  justify-content: center;
}
.nf-form-cont nf-field .submit-container input {
  border: 1px solid #b5bcba;
  color: #b5bcba;
  background: none;
  padding: 5px 10px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.main-sub {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-sub .main-img {
  width: 90%;
  height: auto;
}
.main-sub .sub-img {
  width: 50%;
  height: auto;
  align-self: flex-end;
  margin-top: -4rem;
}

/****************************************

BUTTONS

****************************************/
.btn {
  padding: 10px 20px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  border: 1px solid #d5b868;
  background-color: #d5b868;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  display: table;
  cursor: pointer;
  transition: border 0.5s, background-color 0.5s, color 0.5s;
}
.btn:hover {
  border: 1px solid #5b809b;
  background-color: #5b809b;
  color: #fff;
}
.btn.center {
  margin: 0 auto;
}
.btn.secondary {
  border: 1px solid;
  color: #fff;
}
.btn.secondary:hover {
  border: 1px solid #d5b868;
  background-color: #d5b868;
  color: #fff;
}
.btn.lg {
  display: flex;
  align-items: center;
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #000;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  background: none;
  border: none;
  padding: 0;
  margin: 0 auto;
  text-transform: inherit;
  color: #fff;
}
@media (max-width: 992px) {
  .btn.lg {
    font-size: 2.4rem;
  }
}
.btn.lg:after {
  content: "";
  width: 20px;
  height: 20px;
  flex: none;
  display: block;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: translate(0, 3px) rotate(45deg);
  position: relative;
  z-index: 99;
  transition: all 1s;
}
.btn.lg:hover:after {
  transform: translate(10px, 3px) rotate(45deg);
}
.btn.lg.accent {
  color: #d5b868;
}
.btn.lg.accent:after {
  border-color: #d5b868;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.links *:not(:last-of-type) {
  margin-right: 30px;
}

/****************************************

CONTAINERS

****************************************/
.cont--lg {
  max-width: 1400px;
  margin: 0 auto;
}
@media (max-width: 1450px) {
  .cont--lg {
    padding: 0 2rem;
  }
}

.cont {
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .cont {
    padding: 0 2rem;
  }
}

.cont--sm {
  max-width: 925px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .cont--sm {
    padding: 0 2rem;
  }
}

@media (max-width: 992px) {
  .cont--lg.mc,
  .cont.mc,
  .cont--sm.mc {
    padding: 0;
  }
}

/****************************************

LAYOUTS

****************************************/
.column-layout {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .column-layout {
    flex-direction: column;
  }
}
.column-layout .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc((100% - 8rem) / 2);
}
@media (max-width: 1200px) {
  .column-layout .col {
    width: calc((100% - 2rem) / 2);
  }
}
@media (max-width: 992px) {
  .column-layout .col {
    width: 100%;
  }
}
.column-layout .col--two-thirds {
  width: calc((100% * 2 / 3) - 8rem);
}
@media (max-width: 1200px) {
  .column-layout .col--two-thirds {
    width: calc((100% * 2 / 3) - 2rem);
  }
}
@media (max-width: 992px) {
  .column-layout .col--two-thirds {
    width: 100%;
  }
}
.column-layout .col--third {
  width: 33.3333333333%;
}
@media (max-width: 992px) {
  .column-layout .col--third {
    width: 100%;
  }
}
.column-layout.sm-gap .col {
  width: calc((100% - 2rem) / 2);
}
@media (max-width: 992px) {
  .column-layout.sm-gap .col {
    width: 100%;
  }
}
.column-layout.sticky-right {
  align-items: flex-start;
}
.column-layout.sticky-right .col {
  min-height: calc(100vh - 32px - 2rem - 2rem);
}
.column-layout.sticky-right .col:first-of-type {
  min-height: 200vh;
}
.column-layout.sticky-right .col:last-of-type {
  position: sticky;
  top: calc(32px + 2rem);
}

.card-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}
.card-layout.halves .card {
  width: calc((100% - 8rem) / 2);
  margin-bottom: 8rem;
}
@media (max-width: 1200px) {
  .card-layout.halves .card {
    width: calc((100% - 2rem) / 2);
    margin-bottom: 2rem;
  }
}
@media (max-width: 992px) {
  .card-layout.halves .card {
    width: 100%;
  }
}
.card-layout.halves .card:not(:nth-of-type(even)) {
  margin-right: 8rem;
}
@media (max-width: 1200px) {
  .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 2rem;
  }
}
@media (max-width: 992px) {
  .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 0;
  }
}
.card-layout.halves .card:last-of-type {
  margin-right: 0;
}
.card-layout.thirds .card {
  width: calc((100% - (1rem * 4)) / 3);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.thirds .card {
    width: 100%;
  }
}
.card-layout.thirds .card:not(:nth-of-type(3n + 3)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.thirds .card:not(:nth-of-type(3n + 3)) {
    margin-right: 0;
  }
}
.card-layout.thirds .card:last-of-type {
  margin-right: 0;
}
.card-layout.quarters .card {
  width: calc((100% - (1rem * 6)) / 4);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.quarters .card {
    width: 100%;
  }
}
.card-layout.quarters .card:not(:nth-of-type(4n + 4)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.quarters .card:not(:nth-of-type(4n + 4)) {
    margin-right: 0;
  }
}
.card-layout.quarters .card:last-of-type {
  margin-right: 0;
}
.card-layout.fifths .card {
  width: calc((100% - (1rem * 8)) / 5);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.fifths .card {
    width: 100%;
  }
}
.card-layout.fifths .card:not(:nth-of-type(5n + 6)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.fifths .card:not(:nth-of-type(5n + 6)) {
    margin-right: 0;
  }
}
.card-layout.fifths .card:last-of-type {
  margin-right: 0;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 1200px) {
  .grid-layout {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (max-width: 992px) {
  .grid-layout {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
.grid-layout .card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
}
.grid-layout .desc {
  display: none;
}

.wrap-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrap-layout .col,
.wrap-layout .card,
.wrap-layout .field,
.wrap-layout .group {
  width: calc((100% - 2rem) / 2);
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .wrap-layout .col,
  .wrap-layout .card,
  .wrap-layout .field,
  .wrap-layout .group {
    width: calc((100% - 2rem) / 2);
  }
}
@media (max-width: 992px) {
  .wrap-layout .col,
  .wrap-layout .card,
  .wrap-layout .field,
  .wrap-layout .group {
    width: 100%;
  }
}
.wrap-layout .group .card,
.wrap-layout .group .field {
  width: 100%;
  margin: 0 0 5px 0;
}

/****************************************

accordion SECTION

include js: 
acccordianSection()

optional setting:
data-accordion="solo" : show one item only

basic structure: 
<div class="accordion-section" data-accordion="solo">
    <div class="accordion-item" data-accordion-item>
        <div class="accordion-header" data-accordion-header>
            <div class="accordion-icon">
                <span data-accordion-open>+</span>
                <span data-accordion-close>-</span>
            </div>
            accordion A
        </div>
        <div class="accordion-details">
            <p>accordion A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/
.accordion-section .accordion-item .accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  min-height: 30px;
  cursor: pointer;
  padding: 1rem 0;
  border-bottom: 2px solid #d5b868;
}
.accordion-section .accordion-item .accordion-header p {
  margin-bottom: 0;
  width: 100%;
}
@media (max-width: 992px) {
  .accordion-section .accordion-item .accordion-header p {
    padding-right: 1rem;
  }
}
.accordion-section .accordion-item .accordion-header .accordion-icon {
  width: 20px;
  height: 20px;
  flex: none;
  display: block;
  border-top: 1px solid #d5b868;
  border-right: 1px solid #d5b868;
  transform: translate(-5px, -5px) rotate(135deg);
  position: relative;
  z-index: 99;
  margin: 0 auto;
  transition: all 1s;
}
.accordion-section .accordion-item .accordion-header .accordion-icon span[data-accordion-open],
.accordion-section .accordion-item .accordion-header .accordion-icon span[data-accordion-close] {
  display: none !important;
}
.accordion-section .accordion-item .accordion-details {
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  padding: 0 10px;
  transition: all 0.5s;
}
.accordion-section .accordion-item .accordion-details p {
  min-height: 100px;
}
.accordion-section .accordion-item.open .accordion-header .accordion-icon {
  transform: translate(-5px, 5px) rotate(-45deg);
}
.accordion-section .accordion-item.open .accordion-details {
  max-height: 500px;
  padding: 20px 10px;
}

/****************************************

TAB SECTION
Basic show/hide content area with navigation

include js: 
tabSections()

basic structure: 
<div class="tab-section" data-tab-section>
    <div class="tab-nav">
        <div class="tab-link active" data-tab-target="0">Tab 0</div>
        <div class="tab-link" data-tab-target="1">Tab 1</div>
    </div>
    <div class="tab-cont">
        <div class="tab-item active" data-tab="0">Tab 0 Content</div>
        <div class="tab-item" data-tab="1">Tab 1 Content</div>
    </div>
</div>

optional mobile:
add "mobile-active" to tab deired to be open on mobile 

****************************************/
.tab-section .tab-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 992px) {
  .tab-section .tab-nav {
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .tab-section .tab-nav {
    flex-direction: column;
  }
}
.tab-section .tab-nav .tab-link {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.tab-section .tab-nav .tab-link.active {
  text-decoration: underline;
}
.tab-section .tab-cont {
  position: relative;
  width: 100%;
}
.tab-section .tab-cont .tab-item {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
  transition: opacity 0.5s;
}
.tab-section .tab-cont .tab-item.active {
  position: relative;
  top: auto;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
}
@media (max-width: 576px) {
  .tab-section .tab-cont .tab-item.mobile-active {
    position: relative;
    top: auto;
    width: 100%;
    pointer-events: auto;
    opacity: 1;
    z-index: 1;
  }
}

/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/
.swiper-section {
  position: relative;
  width: 100%;
  height: auto;
}
.swiper-section .swiper-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 992px) {
  .swiper-section .swiper-nav {
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .swiper-section .swiper-nav {
    flex-direction: column;
  }
}
.swiper-section .swiper-nav .swiper-link {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.swiper-section .swiper-nav .swiper-link.active {
  text-decoration: underline;
}
.swiper-section .swiper-cont {
  overflow: hidden;
  position: relative;
}
.swiper-section .swiper-button-prev {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-section .swiper-button-prev:after {
  content: "";
  width: 14px;
  height: 30px;
  background-image: url("../images/slider-arrow.png");
  display: block;
  border: none !important;
}
.swiper-section .swiper-button-next {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-section .swiper-button-next:after {
  content: "";
  width: 14px;
  height: 30px;
  background-image: url("../images/slider-arrow.png");
  transform: rotate(180deg);
  display: block;
  border: none !important;
}
.swiper-section .swiper-button-next.swiper-button-disabled,
.swiper-section .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
}
.swiper-section .swiper-pagination {
  width: 100%;
  position: relative;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: auto;
  margin-top: 0;
  left: auto;
}
@media (max-width: 1450px) {
  .swiper-section .swiper-pagination {
    left: 0px;
  }
}
.swiper-section .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #5b809b;
  transition: all 0.5s;
}
.swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #5b809b;
}
.swiper-section.inset {
  padding: 0 50px;
}
@media (max-width: 768px) {
  .swiper-section.inset.preserve-horizontal {
    padding: 0 30px;
  }
  .swiper-section.inset.preserve-horizontal .swiper-button-prev {
    width: 30px;
    height: 30px;
    top: calc((100% - 30px) / 2);
  }
  .swiper-section.inset.preserve-horizontal .swiper-button-next {
    width: 30px;
    height: 30px;
    top: calc((100% - 30px) / 2);
  }
}
@media (min-width: 992px) {
  .swiper-section.grid-collapse .swiper-button-next,
  .swiper-section.grid-collapse .swiper-button-prev,
  .swiper-section.grid-collapse .swiper-pagination {
    display: none;
  }
}
@media (min-width: 992px) {
  .swiper-section.grid-collapse .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }
}
.swiper-section.grid-collapse.halves .swiper-slide {
  width: calc((100% - 8rem) / 2);
}
@media (max-width: 1200px) {
  .swiper-section.grid-collapse.halves .swiper-slide {
    width: calc((100% - 2rem) / 2);
    margin-bottom: 2rem;
  }
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.halves .swiper-slide {
    width: 100%;
  }
}
.swiper-section.grid-collapse.halves .swiper-slide:not(:nth-of-type(even)) {
  margin-right: 8rem;
}
@media (max-width: 1200px) {
  .swiper-section.grid-collapse.halves .swiper-slide:not(:nth-of-type(even)) {
    margin-right: 2rem;
  }
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.halves .swiper-slide:not(:nth-of-type(even)) {
    margin-right: 0;
  }
}
.swiper-section.grid-collapse.halves .swiper-slide:last-of-type {
  margin-right: 0;
}
.swiper-section.grid-collapse.thirds .swiper-slide {
  width: calc((100% - (1rem * 4)) / 3);
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.thirds .swiper-slide {
    width: 100%;
    margin-bottom: 0;
  }
}
.swiper-section.grid-collapse.thirds .swiper-slide:not(:nth-of-type(3n + 3)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.thirds .swiper-slide:not(:nth-of-type(3n + 3)) {
    margin-right: 0;
  }
}
.swiper-section.grid-collapse.thirds .swiper-slide:last-of-type {
  margin-right: 0;
}
.swiper-section.grid-collapse.quarters .swiper-slide {
  width: calc((100% - (1rem * 6)) / 4);
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.quarters .swiper-slide {
    width: 100%;
  }
}
.swiper-section.grid-collapse.quarters .swiper-slide:not(:nth-of-type(4n + 4)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .swiper-section.grid-collapse.quarters .swiper-slide:not(:nth-of-type(4n + 4)) {
    margin-right: 0;
  }
}
.swiper-section.grid-collapse.quarters .swiper-slide:last-of-type {
  margin-right: 0;
}

/****************************************

SITE SPECIFIC

****************************************/
.swiper-section.style-arrow.inset {
  padding: 0 100px;
}
@media (max-width: 768px) {
  .swiper-section.style-arrow.inset {
    padding: 0;
  }
}
.swiper-section.style-arrow .swiper-button-prev {
  background: none;
  width: 100px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-section.style-arrow .swiper-button-prev:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../images/arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  display: block;
  border: none !important;
}
.swiper-section.style-arrow .swiper-button-next {
  background: none;
  width: 100px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-section.style-arrow .swiper-button-next:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../images/arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  transform: none !important;
  border: none !important;
}
@media (max-width: 768px) {
  .swiper-section:not(.preserve-horizontal) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .swiper-section:not(.preserve-horizontal).inset {
    padding: 0;
  }
  .swiper-section:not(.preserve-horizontal) .swiper-cont {
    width: 100%;
    height: auto;
  }
  .swiper-section:not(.preserve-horizontal) .swiper-button-prev {
    width: 30px;
    height: 30px;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    position: relative;
    margin: 10px 5px;
  }
  .swiper-section:not(.preserve-horizontal) .swiper-button-next {
    width: 30px;
    height: 30px;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    position: relative;
    margin: 10px 5px;
  }
}

.filters {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 4rem;
}
.filters .col {
  width: 100%;
}
.filters .rw {
  display: flex;
  width: 100%;
  align-items: center;
}
@media (max-width: 992px) {
  .filters .rw {
    flex-direction: column;
  }
}
.filters .rw:not(:last-of-type) {
  margin-bottom: 4rem;
}

.filter {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6875;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  border: none;
  margin: 0 0 0 0;
  padding: 0;
}
.filter sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
.filter.disclaimer {
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.filter.eyebrow {
  font-size: 1rem;
  line-height: 2.1rem;
}
.filter.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
.filter a {
  color: #000;
  text-decoration: underline;
}
@media (max-width: 992px) {
  .filter {
    margin-bottom: 1rem;
  }
}
.filter label {
  height: auto;
  line-height: 1;
  margin-right: 2rem;
}
@media (max-width: 992px) {
  .filter label {
    min-width: 75px;
    text-align: right;
  }
}
.filter p.dropdown {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  margin: 0;
  color: #5b809b;
  cursor: pointer;
  border: 1px solid #5b809b;
}
.filter p.dropdown:after {
  content: "";
  width: 10px;
  height: 10px;
  border-right: 1px solid #5b809b;
  border-top: 1px solid #5b809b;
  margin-left: 2rem;
  transform: translate(0, -2px) rotate(135deg);
  display: block;
  transition: transform 0.5s;
}
.filter.active p.dropdown:after {
  transform: translate(0, 3px) rotate(-45deg);
}
.filter fieldset {
  border: none;
  padding: 0;
  position: relative;
}
.filter select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-appearance: none;
  border: none;
}
.filter.overlay-filter {
  position: relative;
  z-index: 99;
}
.filter.overlay-filter .overlay {
  position: absolute;
  top: 80px;
  left: 0;
  min-width: 225px;
  padding: 10px;
  border: 1px solid #000;
  background: #fff;
  z-index: 9;
  display: flex;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}
@media (max-width: 992px) {
  .filter.overlay-filter .overlay {
    position: relative;
    max-height: 0;
    padding: 0px 10px;
    top: auto;
    left: auto;
    overflow: hidden;
    transition: max-width 0.5s, opacity 0.5s;
  }
}
.filter.overlay-filter .overlay .filter {
  margin: 0;
  width: 100%;
  padding: 0 10px;
}
.filter.overlay-filter .overlay .filter:nth-of-type(2) {
  border-left: 1px solid #000;
}
.filter.overlay-filter.active .overlay {
  opacity: 1;
  pointer-events: auto;
}
@media (max-width: 992px) {
  .filter.overlay-filter.active .overlay {
    max-height: 900px;
    padding: 10px;
  }
}
.filter.home-styles {
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 992px) {
  .filter.home-styles {
    align-items: center;
    margin-bottom: 2rem;
  }
}
.filter.home-styles > label {
  display: none;
}
.filter.home-styles .filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter.home-styles .filter label:not(:last-of-type) {
  margin-bottom: 1rem;
}
.filter .cb {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.filter .cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filter .cb input:checked ~ .checkmark {
  background-color: #5b809b;
}
.filter .cb input:checked ~ .checkmark:after {
  display: block;
}
.filter .cb .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: none;
  border: 1px solid #5b809b;
  transition: background-color 0.5s;
}
.filter .cb .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.filter.sqft .flex {
  display: flex;
  flex-direction: column;
}
.filter.sqft label {
  text-align: center;
  margin-right: 0;
  margin-bottom: 2rem;
  width: 100%;
}

.range_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
@media (max-width: 992px) {
  .range_container {
    margin: 1rem 0 0;
  }
}
.range_container .sliders_control {
  position: relative;
  width: 100%;
}
.range_container .scale {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.range_container .scale span {
  width: auto;
}
.range_container input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 15px;
  height: 15px;
  background-color: #5b809b;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.range_container input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.range_container input[type=range]::-webkit-slider-thumb:active {
  box-shadow: inset 0 0 3px #5b809b, 0 0 9px #5b809b;
}
.range_container input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #c6c6c6;
  pointer-events: none;
}
.range_container #fromSlider {
  height: 0;
  z-index: 1;
}
.range_container .min,
.range_container .max {
  position: absolute;
  top: 10px;
  font-size: 12px;
  width: 50px;
  transform: translate(-17.5px, 0);
  text-align: center;
}
.range_container .max {
  transform: translate(-32.5px, 0);
}

/****************************************

FIXED HEADER

****************************************/
.logged-in header {
  top: 32px;
}
@media (max-width: 576px) {
  .logged-in header {
    top: 45px;
  }
  .logged-in header.scroll {
    top: 0;
  }
}

header {
  width: 100vw;
  z-index: 999;
  padding: 1rem 0;
  position: fixed;
  transition: all 0.5s;
}
@media (max-width: 992px) {
  header {
    display: block;
    padding: 10px;
  }
  header.scroll {
    min-height: 50px;
    padding: 10px;
  }
}
header .logo {
  height: 85px;
  width: auto;
  display: block;
}
@media (max-width: 992px) {
  header .logo {
    height: 50px;
  }
}
header .logo img {
  height: 100%;
  width: auto;
}
header .logo img.white {
  display: none;
}
header .inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
@media (max-width: 992px) {
  header .inner {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
header .inner .col {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
}
header .inner .col a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 2rem;
}
@media (max-width: 992px) {
  header .inner .col a {
    display: none;
  }
}
@media (max-width: 992px) {
  header .cont {
    width: 100%;
    margin: 0;
  }
}
header .menu-toggle {
  width: 50px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background: #d5b868;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.5s;
}
header .menu-toggle .line {
  border-bottom: 2px solid #fff;
  width: 30px;
  height: 1px;
  position: relative;
  display: block;
  transition: all 0.5s;
}
header.active .menu-toggle {
  justify-content: center;
}
header.active .menu-toggle .line:nth-of-type(3) {
  opacity: 0;
}
header.active .menu-toggle .line:nth-of-type(1) {
  transform: rotate(45deg);
}
header.active .menu-toggle .line:nth-of-type(2) {
  transform: rotate(-45deg) translate(1px, -2px);
}
header.active .inner .col a {
  color: #000;
  z-index: 9;
}

@media (max-width: 992px) {
  body.scroll {
    padding-top: 0;
  }
}
body.scroll header {
  background: #d5b868;
}
body.scroll header .logo .color {
  display: none;
}
body.scroll header .logo .white {
  display: block;
}
body.scroll header .menu-toggle {
  background: #fff;
}
body.scroll header .menu-toggle .line {
  border-bottom: 2px solid #d5b868;
}

#main-nav ul li {
  position: relative;
}
@media (max-width: 992px) {
  #main-nav ul li {
    width: 100%;
  }
}
#main-nav ul li.menu-item-has-children a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 992px) {
  #main-nav ul li.menu-item-has-children a {
    justify-content: flex-start;
  }
}
#main-nav ul li.menu-item-has-children a:after {
  width: 7px;
  height: 7px;
  display: block;
  margin-left: 10px;
  border-right: 1px solid #d5b868;
  border-top: 1px solid #d5b868;
  transform: translate(0, -3px) rotate(135deg);
  transition: transform 0.5s;
}
#main-nav ul li.current-menu-parent > a {
  text-decoration: underline;
}
#main-nav ul li.current-menu-item a {
  color: #d5b868;
}
#main-nav ul a {
  font-size: fontsize(nav);
  line-height: 1.2;
  text-decoration: none;
  transition: color 0.5s;
}
#main-nav ul a:hover {
  color: #d5b868;
}

.header-spacing {
  height: 77px;
  width: 100%;
}

/****************************************

Takeover Nav

****************************************/
header.active nav {
  width: 100vw;
  right: 0;
}
header.scroll nav {
  background: rgba(255, 255, 255, 0.95) !important;
}
header nav {
  width: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95) !important;
  transition: width 0.5s, padding 0.5s, right 0.75s;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  right: -100vw;
  background: #fff;
}
@media (max-width: 992px) {
  header nav {
    padding-top: 50px;
  }
}
header nav .menu {
  width: 100vw;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  padding: 5px 10px 5px 175px;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  padding: 5px 10px;
  max-width: 500px;
  margin: 0 auto;
}
header nav .menu li {
  margin: 0;
}
header nav .menu li:not(:last-of-type) {
  margin-bottom: 10px;
}
header nav .menu li a {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #000;
  margin-bottom: 2rem;
  margin: 0;
  color: #000;
  text-decoration: none;
  font-family: "EB Garamond", serif;
  padding: 12px 10px;
  min-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: auto;
  position: relative;
  font-weight: 100;
  transition: color 0.5s;
  min-height: inherit;
  padding: 5px 10px;
}
@media (max-width: 992px) {
  header nav .menu li a {
    font-size: 2.4rem;
  }
}
header nav .menu li a.active {
  color: #d5b868;
}
header nav .menu li.current_page_item a {
  color: #d5b868;
}
header nav .menu li.current_page_item ul li a {
  color: #000;
}
header nav .menu li.current_page_item ul li.current_page_item a {
  color: #d5b868;
}
header nav .menu li.menu-item-has-children {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
header nav .menu li.menu-item-has-children a {
  order: -1;
}
header nav .menu li.menu-item-has-children .toggle-menu {
  content: "";
  width: 15px;
  height: 15px;
  border-right: 2px solid #000;
  border-top: 2px solid #000;
  transform: rotate(135deg) translate(-5px, 0);
  transition: transform 0.5s;
  display: block;
  margin-left: 15px;
  cursor: pointer;
}
header nav .menu li.menu-item-has-children ul {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
header nav .menu li.menu-item-has-children.active > .toggle-menu {
  transform: rotate(-45deg) translate(0px, 10px);
}
header nav .menu li.menu-item-has-children.active ul {
  max-height: 1000px;
}
header .highlight-menu {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  header .highlight-menu {
    width: 100%;
    flex-direction: column;
    display: none;
  }
  header .highlight-menu .menu-highlight-menu-container {
    width: 100%;
  }
}
header .highlight-menu .current {
  display: none;
  text-align: right;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  text-decoration: none;
  letter-spacing: 1.5px;
}
header .highlight-menu ul {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  header .highlight-menu ul {
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
}
header .highlight-menu ul a {
  text-transform: uppercase;
  padding: 0 15px;
  text-decoration: none;
  letter-spacing: 1.5px;
}
@media (max-width: 992px) {
  header .highlight-menu ul a {
    width: 100%;
    text-align: right;
    padding-right: 0;
  }
}
header .highlight-menu ul li.current_page_item a,
header .highlight-menu ul li.current-page-ancestor a {
  text-decoration: underline;
}
header .highlight-menu ul li.current_page_item ul li a,
header .highlight-menu ul li.current-page-ancestor ul li a {
  text-decoration: none;
}
header .highlight-menu ul li.current_page_item ul li.current_page_item a,
header .highlight-menu ul li.current-page-ancestor ul li.current_page_item a {
  text-decoration: underline;
}
header .highlight-menu ul li.menu-item-has-children {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 1440px) {
  header .highlight-menu ul li.menu-item-has-children {
    position: inherit;
  }
}
header .highlight-menu ul li.menu-item-has-children ul {
  display: none !important;
  width: 200px;
  position: absolute;
  top: 25px;
  left: -10px;
  width: 280px;
  background: white;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s;
}
@media (max-width: 1440px) {
  header .highlight-menu ul li.menu-item-has-children ul {
    left: auto;
    right: 0;
    top: 45px;
  }
}
header .highlight-menu ul li.menu-item-has-children.active > li:after, header .highlight-menu ul li.menu-item-has-children:hover > li:after {
  transform: rotate(-45deg) translate(0px, 10px);
}
header .highlight-menu ul li.menu-item-has-children.active ul, header .highlight-menu ul li.menu-item-has-children:hover ul {
  max-height: 1000px;
  padding: 10px;
}
header .highlight-menu.active ul {
  max-height: 500px;
}

@media (max-width: 992px) {
  body.scroll {
    padding-top: 0;
  }
}
body.scroll header {
  backdrop-filter: blur(5px);
}

.header-spacing {
  width: 100%;
  height: 59px;
}
@media (max-width: 992px) {
  .header-spacing {
    height: 60px;
  }
}

/****************************************

FOOTER

****************************************/
footer {
  background: #5b809b;
  padding: 4rem 0;
}
@media (max-width: 992px) {
  footer {
    padding: 2rem 0;
  }
}
footer p,
footer a {
  margin: 0;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  text-decoration: none;
}
footer .social {
  justify-content: center;
}
footer .logo {
  height: 85px;
  width: auto;
  margin: 0px auto;
}
footer .logo img {
  height: 100%;
  width: auto;
}
footer .logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  footer .logos {
    margin-bottom: 2rem;
  }
}
footer .logos img {
  height: 70px;
  width: auto;
  margin: 0 5px;
}
@media (max-width: 992px) {
  footer .column-layout .col {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
footer .column-layout .col:nth-of-type(1) {
  width: 165px;
  flex: none;
}
@media (max-width: 992px) {
  footer .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
footer .column-layout .col:nth-of-type(2) {
  width: 100%;
}
@media (max-width: 992px) {
  footer .column-layout .col:nth-of-type(2) {
    margin: 1rem 0;
  }
}
footer .column-layout .col:nth-of-type(2) .contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .column-layout .col:nth-of-type(3) {
  width: 150px;
}
footer .column-layout .col:nth-of-type(4) {
  width: 100px;
  flex: none;
  align-items: flex-end;
}
@media (max-width: 992px) {
  footer .column-layout .col:nth-of-type(4) {
    align-items: center;
  }
}

.footer-form .nf-form-cont .nf-form-title h3 {
  font-family: "EB Garamond", serif;
  font-weight: 100;
  font-size: 3rem;
  line-height: 1.6666666667;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
}
@media (max-width: 992px) {
  .footer-form .nf-form-cont .nf-form-title h3 {
    font-size: 2.4rem;
  }
}
.footer-form .nf-form-cont .nf-form-wrap {
  width: 100%;
}
.footer-form .nf-form-cont .nf-form-wrap .nf-before-form-content {
  display: none;
}
.footer-form .nf-form-cont .nf-form-wrap .nf-form-content {
  padding: 0;
}
.footer-form .nf-form-cont .nf-form-wrap nf-fields-wrap {
  display: flex;
  flex-wrap: wrap;
}
.footer-form .nf-form-cont .nf-form-wrap nf-field {
  width: calc((100% - 8rem) / 2);
  display: flex;
}
@media (max-width: 992px) {
  .footer-form .nf-form-cont .nf-form-wrap nf-field {
    width: 100%;
  }
}
.footer-form .nf-form-cont .nf-form-wrap nf-field:nth-of-type(odd) {
  margin-right: 8rem;
}
@media (max-width: 992px) {
  .footer-form .nf-form-cont .nf-form-wrap nf-field:nth-of-type(odd) {
    margin-right: 0;
  }
}
.footer-form .nf-form-cont .nf-form-wrap nf-field .nf-field-container {
  width: 100%;
  margin-bottom: 1rem;
}
.footer-form .nf-form-cont .nf-form-wrap nf-field input,
.footer-form .nf-form-cont .nf-form-wrap nf-field textarea {
  background: #b5bcba;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
.footer-form .nf-form-cont .nf-form-wrap nf-field textarea {
  height: 50px;
  font-family: "Montserrat", sans-serif;
}
.footer-form .nf-form-cont .nf-form-wrap nf-field .submit-container input {
  background: none;
  font-weight: bold;
  color: #000 !important;
  text-align: left;
  padding: 10px 0;
}
.footer-form .nf-form-cont .nf-form-wrap nf-field .recaptcha-wrap label {
  display: none;
}

.map-section .column-layout .col:nth-of-type(1) {
  width: 300px;
}
.map-section .column-layout .col:nth-of-type(2) {
  width: calc(100% - 300px);
}
.map-section [data-map] {
  width: 100%;
  height: 100%;
  height: 500px;
}
.map-section [data-categories] {
  height: 200px;
  border: 1px solid black;
  width: 100%;
}
.map-section [data-categories] p {
  margin: 0;
}
.map-section [data-categories] .category {
  cursor: pointer;
}
.map-section [data-categories] .category p {
  margin: 0;
  line-height: 1.3;
}
.map-section [data-categories] .category.active p {
  font-weight: bold;
}
.map-section [data-pois] {
  width: 100%;
  height: 300px;
  overflow: scroll;
  position: relative;
  scroll-behavior: smooth;
  border: 1px solid black;
}
.map-section [data-pois] .poi {
  display: none;
}
.map-section [data-pois] .poi.active {
  display: block;
}
.map-section [data-key] .card {
  padding: 10px;
}
.map-section [data-key] .card p.title4 {
  margin: 0;
}
.map-section [data-key] .card .item {
  cursor: pointer;
}
.map-section [data-key] .card .item p {
  margin-bottom: 0;
}
.map-section [data-key] .card .toggle {
  cursor: pointer;
}
.map-section [data-key] .card .toggle .show {
  display: block;
}
.map-section [data-key] .card .toggle .hide {
  display: none;
}
.map-section [data-key] .card.active .toggle .show {
  display: none;
}
.map-section [data-key] .card.active .toggle .hide {
  display: block;
}
.map-section .info-window {
  width: 300px;
  padding: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-section .info-window p {
  margin: 0 0 5px 0;
  line-height: 1.2;
  text-align: center;
}
.map-section .info-window .img-crop {
  margin-bottom: 5px;
}
.map-section .info-window .btn {
  margin: 10px auto;
}
.map-section .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.map-section .gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.map-section .gm-style [aria-label=Close] {
  width: 20px !important;
  height: 20px !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid black !important;
}
.map-section .gm-style [aria-label=Close] span {
  margin: 0 !important;
}

.video-bg {
  min-height: 500px;
  height: 100vh;
  max-height: 1080px;
  width: 100%;
  overflow: hidden;
  padding: 0;
  text-align: center;
}
@media (max-width: 1024px) {
  .video-bg {
    min-height: 100vh;
    height: 100vh;
    margin-bottom: 0;
    background-color: 0;
  }
}
.video-bg .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.video-bg .cover .inner h1 {
  color: white;
}
.video-bg iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-bg video {
  position: relative;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  overflow: hidden;
}

.floorplans-template-default .intro-section,
.quickdeliveries-template-default .intro-section {
  margin-top: 4rem;
}
.floorplans-template-default .intro-section .column-layout,
.quickdeliveries-template-default .intro-section .column-layout {
  align-items: stretch;
  justify-content: center;
}
.floorplans-template-default .intro-section .column-layout > .col:nth-of-type(1),
.quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(1) {
  width: 60%;
  z-index: 9;
  margin-right: -2rem;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .floorplans-template-default .intro-section .column-layout > .col:nth-of-type(1),
  .quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(1) {
    width: calc(100% - 2rem);
    margin-right: 0;
  }
}
.floorplans-template-default .intro-section .column-layout > .col:nth-of-type(1) img,
.quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(1) img {
  width: 100%;
}
.floorplans-template-default .intro-section .column-layout > .col:nth-of-type(2),
.quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(2) {
  width: 40%;
}
@media (max-width: 992px) {
  .floorplans-template-default .intro-section .column-layout > .col:nth-of-type(2),
  .quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(2) {
    width: calc(100% - 2rem);
    margin-top: -2rem;
    align-self: flex-end;
  }
}
.floorplans-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner,
.quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner {
  padding: 8rem 4rem 8rem 6rem;
  margin-top: 2rem;
  background: #5b809b;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .floorplans-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner,
  .quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner {
    padding: 4rem 4rem;
    margin-top: 0;
  }
}
.floorplans-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner p,
.quickdeliveries-template-default .intro-section .column-layout > .col:nth-of-type(2) .inner p {
  color: #fff;
}
.floorplans-template-default .intro-section .column-layout h1,
.quickdeliveries-template-default .intro-section .column-layout h1 {
  color: #fff;
  text-align: center;
  width: 100%;
}
.floorplans-template-default .intro-section .stats,
.quickdeliveries-template-default .intro-section .stats {
  max-width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-direction: row !important;
}
.floorplans-template-default .intro-section .stats .col,
.quickdeliveries-template-default .intro-section .stats .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 1.4rem;
}
.floorplans-template-default .intro-section .stats .col span,
.quickdeliveries-template-default .intro-section .stats .col span {
  color: #fff;
  font-weight: bold;
}
.floorplans-template-default .intro-section p,
.quickdeliveries-template-default .intro-section p {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}
.floorplans-template-default .intro-section p:last-of-type,
.quickdeliveries-template-default .intro-section p:last-of-type {
  margin-bottom: 1rem;
}
.floorplans-template-default .intro-section .desc p,
.quickdeliveries-template-default .intro-section .desc p {
  text-transform: inherit;
  text-align: left;
}
.floorplans-template-default .floorplans-section,
.quickdeliveries-template-default .floorplans-section {
  background: #fff;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(1),
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(1) {
  width: 200px;
  flex: none;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(1),
  .quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.floorplans-template-default .floorplans-section .column-layout .col .tab-nav,
.quickdeliveries-template-default .floorplans-section .column-layout .col .tab-nav {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 992px) {
  .floorplans-template-default .floorplans-section .column-layout .col .tab-nav,
  .quickdeliveries-template-default .floorplans-section .column-layout .col .tab-nav {
    margin-bottom: 2rem;
  }
}
.floorplans-template-default .floorplans-section .column-layout .col .tab-nav .tab-link,
.quickdeliveries-template-default .floorplans-section .column-layout .col .tab-nav .tab-link {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #000;
  justify-content: flex-start;
  text-align: left;
  padding: 0;
}
.floorplans-template-default .floorplans-section .column-layout .col .tab-nav .tab-link.active,
.quickdeliveries-template-default .floorplans-section .column-layout .col .tab-nav .tab-link.active {
  text-decoration: none;
  color: #5b809b;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2),
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) {
  width: 100%;
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2),
  .quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) {
    padding-left: 0;
  }
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox],
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox] {
  display: block;
  position: relative;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox] img,
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox] img {
  position: Relative;
  z-index: 0;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox]:before,
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox]:before {
  content: "Click to View Larger";
  position: absolute;
  font-size: 36px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #fff;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox]:hover:before,
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) a[data-fancybox]:hover:before {
  opacity: 1;
}
.floorplans-template-default .floorplans-section .column-layout .col:nth-of-type(2) .tab-item img,
.quickdeliveries-template-default .floorplans-section .column-layout .col:nth-of-type(2) .tab-item img {
  display: block;
  margin: 0 auto;
}
.floorplans-template-default .gallery-section .swiper-section,
.quickdeliveries-template-default .gallery-section .swiper-section {
  margin-top: 4rem;
}
.floorplans-template-default .gallery-section .swiper-cont.thumb-swiper .swiper-slide,
.quickdeliveries-template-default .gallery-section .swiper-cont.thumb-swiper .swiper-slide {
  cursor: pointer;
}
.floorplans-template-default .gallery-section .swiper-button-next svg,
.quickdeliveries-template-default .gallery-section .swiper-button-next svg {
  transform: rotate(180deg) translate(-15px, 0);
}

.page-template-template-floor-plans-index .hero-section {
  background: #fff;
}
.page-template-template-floor-plans-index .filters-top {
  background: #fff;
  padding: 4rem 0;
  margin-bottom: 4rem;
}
.page-template-template-floor-plans-index .filters-top .filters {
  margin-top: 0;
}
.page-template-template-floor-plans-index .fp-filters .run-search {
  display: none;
}
.page-template-template-floor-plans-index .all-floor-plans {
  padding-top: 0;
}
.page-template-template-floor-plans-index .all-floor-plans .count {
  display: none;
}
.page-template-template-floor-plans-index .all-floor-plans .count.active {
  display: block;
}
.page-template-template-floor-plans-index .all-floor-plans .card.hide {
  display: none;
}

.card.fp-card .stats {
  flex-direction: column;
  font-size: 1.4rem;
}

.quickdeliveries-template-default .intro-section .stats {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6875;
  margin-bottom: 2rem;
}
.quickdeliveries-template-default .intro-section .stats sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
.quickdeliveries-template-default .intro-section .stats.disclaimer {
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.quickdeliveries-template-default .intro-section .stats.eyebrow {
  font-size: 1rem;
  line-height: 2.1rem;
}
.quickdeliveries-template-default .intro-section .stats.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
.quickdeliveries-template-default .intro-section .stats a {
  color: #000;
  text-decoration: underline;
}
.quickdeliveries-template-default .map-section .column-layout .col {
  width: 50%;
}

.blog-entry {
  display: flex;
  align-items: flex-start;
}
.blog-entry:not(:last-of-type) {
  margin-bottom: 2rem;
}
.blog-entry .thumbnail {
  width: 30%;
}
.blog-entry .thumbnail img {
  width: 100%;
  height: auto;
}
.blog-entry .inner {
  width: 70%;
  padding-left: 2rem;
}
.blog-entry .inner a {
  text-decoration: none;
}

.blog-posts {
  padding: 2rem 0;
}

.blog .navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.blog .navigation a,
.blog .navigation .current {
  color: #fff;
  background: #d5b868;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1.6rem;
}
.single .column-layout .col.col--third {
  justify-content: flex-start;
}

.page-template-homebuilder .map-section {
  padding: 0;
}
.page-template-homebuilder .map-section [data-categories] {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  padding: 2rem 0;
}
.page-template-homebuilder .map-section [data-map],
.page-template-homebuilder .map-section [data-pois] {
  height: calc(100vh - 400px);
}

.logged-in .top {
  height: calc(100vh - 32px);
}
.logged-in .top .swiper-slide {
  height: calc(100vh - 32px);
}

.home .hero-section .column-layout {
  width: 100%;
}
.home .hero-section .column-layout .col:nth-of-type(1), .home .hero-section .column-layout .col:nth-of-type(3) {
  width: 215px;
  flex: none;
}
@media (max-width: 992px) {
  .home .hero-section .column-layout .col:nth-of-type(1), .home .hero-section .column-layout .col:nth-of-type(3) {
    width: 100%;
  }
}
.home .hero-section .column-layout .col:nth-of-type(1) .btn, .home .hero-section .column-layout .col:nth-of-type(3) .btn {
  text-transform: uppercase;
  width: 100%;
}
.home .hero-section .column-layout .col:nth-of-type(1) .btn:not(:last-of-type), .home .hero-section .column-layout .col:nth-of-type(3) .btn:not(:last-of-type) {
  margin-bottom: 1rem;
}
.home .hero-section .column-layout .col:nth-of-type(2) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.home .alternating-sections .column-layout {
  align-items: stretch;
  justify-content: center;
}
.home .alternating-sections .column-layout .col:nth-of-type(1) {
  width: 60%;
  z-index: 9;
}
@media (max-width: 992px) {
  .home .alternating-sections .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.home .alternating-sections .column-layout .col:nth-of-type(2) {
  width: 40%;
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .home .alternating-sections .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding: 4rem 2rem 0;
  }
}
.home .alternating-sections .column-layout h2 {
  color: #5b809b;
  width: 100%;
}
.home .alternating-sections .column-layout:not(:last-of-type) {
  margin-bottom: 8rem;
}
.home .alternating-sections .column-layout:nth-of-type(even) {
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .home .alternating-sections .column-layout:nth-of-type(even) {
    flex-direction: column;
  }
}
.home .alternating-sections .column-layout:nth-of-type(even) .col:nth-of-type(2) {
  padding-left: 0;
  padding-right: 4rem;
}
@media (max-width: 992px) {
  .home .alternating-sections .column-layout:nth-of-type(even) .col:nth-of-type(2) {
    padding: 4rem 2rem 0;
  }
}
.home .alternating-sections.btm .column-layout {
  flex-direction: row;
}
@media (max-width: 992px) {
  .home .alternating-sections.btm .column-layout {
    flex-direction: column;
  }
}
.home .alternating-sections.btm .column-layout .col:nth-of-type(1) {
  width: 60%;
  z-index: 9;
}
@media (max-width: 992px) {
  .home .alternating-sections.btm .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.home .alternating-sections.btm .column-layout .col:nth-of-type(2) {
  width: 40%;
  padding-left: 4rem;
  padding-right: 0;
}
@media (max-width: 992px) {
  .home .alternating-sections.btm .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding: 4rem 2rem 0;
  }
}
.home .alternating-sections.btm .column-layout:nth-of-type(odd) {
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .home .alternating-sections.btm .column-layout:nth-of-type(odd) {
    flex-direction: column;
  }
}
.home .alternating-sections.btm .column-layout:nth-of-type(odd) .col:nth-of-type(2) {
  padding-left: 0;
  padding-right: 4rem;
}
@media (max-width: 992px) {
  .home .alternating-sections.btm .column-layout:nth-of-type(odd) .col:nth-of-type(2) {
    padding: 4rem 2rem 0;
  }
}
.home .specials-section .swiper-section {
  margin-top: 4rem;
}
.home .floor-plans-section {
  background: #fff;
}
.home .gallery-section {
  padding-top: 0;
}
.home .gallery-section .mosaic-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: 500px;
}
.home .gallery-section .mosaic-section .card {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
}
.home .gallery-section .mosaic-section .card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .gallery-section .mosaic-section .card:nth-of-type(1) {
  grid-area: 1/1/4/2;
}
.home .gallery-section .mosaic-section .card:nth-of-type(2) {
  grid-area: 4/1/7/2;
}
.home .gallery-section .mosaic-section .card:nth-of-type(3) {
  grid-area: 1/2/3/3;
}
.home .gallery-section .mosaic-section .card:nth-of-type(4) {
  grid-area: 3/2/5/3;
}
.home .gallery-section .mosaic-section .card:nth-of-type(5) {
  grid-area: 5/2/7/3;
}
.home .gallery-section .mosaic-section .card:nth-of-type(6) {
  grid-area: 1/3/7/4;
}
@media (max-width: 992px) {
  .home .gallery-section .mosaic-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(1) {
    grid-area: 1/1/4/2;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(2) {
    grid-area: 4/1/7/2;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(3) {
    grid-area: 1/2/3/3;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(4) {
    grid-area: 3/2/5/3;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(5) {
    grid-area: 5/2/7/3;
  }
  .home .gallery-section .mosaic-section .card:nth-of-type(6) {
    display: none;
  }
}

.qd-card {
  background: #fff;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  border: 1px solid #5b809b;
}
.qd-card img {
  width: 100%;
}
.qd-card .btm {
  padding: 2rem;
}
.qd-card .btm p.title4 {
  text-align: center;
  color: #5b809b;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
}
.qd-card .btm .stats {
  border-bottom: 1px solid #5b809b;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  flex-direction: row !important;
}
.qd-card .btm .stats .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 1.4rem;
}
.qd-card .btm .stats .col span {
  color: #5b809b;
  font-weight: bold;
}
.qd-card .btm p {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}
.qd-card .btm p:last-of-type {
  margin-bottom: 2rem;
}
.qd-card .btm .arrow {
  content: "";
  width: 20px;
  height: 20px;
  flex: none;
  display: block;
  border-top: 1px solid #d5b868;
  border-right: 1px solid #d5b868;
  transform: rotate(45deg);
  position: relative;
  z-index: 99;
  margin: 0 auto;
  transition: all 1s;
}
.page-template-template-custom .intro-section {
  padding-top: 4rem;
}
.page-template-template-custom .intro-section .column-layout {
  align-items: stretch;
  justify-content: space-between;
}
.page-template-template-custom .intro-section .column-layout h2 {
  color: #5b809b;
}
.page-template-template-custom .intro-section .column-layout .col {
  width: 50%;
}
@media (max-width: 992px) {
  .page-template-template-custom .intro-section .column-layout .col {
    width: 100%;
  }
}
.page-template-template-custom .intro-section .column-layout .col:nth-of-type(1) {
  border-right: 1px solid #5b809b;
  padding-right: 4rem;
}
@media (max-width: 992px) {
  .page-template-template-custom .intro-section .column-layout .col:nth-of-type(1) {
    border-right: none;
    border-bottom: 1px solid #5b809b;
    padding-right: 0;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
  }
}
.page-template-template-custom .intro-section .column-layout .col:nth-of-type(2) {
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .page-template-template-custom .intro-section .column-layout .col:nth-of-type(2) {
    padding-left: 0;
  }
}
.page-template-template-custom .gallery-section .swiper-section {
  margin-top: 4rem;
}

.page-template-template-semi-custom .hero-section .btn {
  margin: 2rem auto 0;
}
.page-template-template-semi-custom .intro-section {
  padding-top: 0;
  margin-top: -4rem;
  z-index: 9;
}
@media (max-width: 992px) {
  .page-template-template-semi-custom .intro-section {
    margin-top: 0;
  }
}
.page-template-template-semi-custom .intro-section .column-layout {
  align-items: stretch;
  justify-content: center;
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) {
  width: 60%;
  z-index: 9;
  padding-top: 8rem;
}
@media (max-width: 992px) {
  .page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) {
    padding-top: 4rem;
    width: calc(100% - 2rem);
  }
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) h2 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) h2,
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) p {
  width: calc(100% - 8rem);
  margin-left: 4rem;
}
@media (max-width: 992px) {
  .page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) h2,
  .page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) p {
    padding: 0 2rem;
    margin-left: 0;
    width: 100%;
  }
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(1) img {
  margin-top: 4rem;
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(2) {
  width: 40%;
  padding-top: 2rem;
  margin-left: -2rem;
}
@media (max-width: 992px) {
  .page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(2) {
    width: calc(100% - 2rem);
    margin-left: 0;
    padding-top: 0;
    align-self: flex-end;
    margin-top: -2rem;
  }
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(2) .inner {
  background: #d5b868;
  padding: 4rem 4rem 4rem 6rem;
  height: 100%;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  .page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(2) .inner {
    padding: 4rem 2rem;
  }
}
.page-template-template-semi-custom .intro-section .column-layout .col:nth-of-type(2) p.title {
  color: #fff;
  margin-bottom: 0;
}
.page-template-template-semi-custom .floor-plans-section {
  background: #fff;
}

.page-template-template-about-us .intro-section .column-layout {
  align-items: center;
}
.page-template-template-about-us .intro-section .column-layout .col:nth-of-type(1) .inner {
  padding: 16rem 10rem 16rem 8rem;
}
@media (max-width: 992px) {
  .page-template-template-about-us .intro-section .column-layout .col:nth-of-type(1) .inner {
    padding: 4rem 2rem;
  }
}
.page-template-template-about-us .video-section .column-layout {
  flex-direction: row-reverse;
  align-items: flex-start;
}
@media (max-width: 992px) {
  .page-template-template-about-us .video-section .column-layout {
    flex-direction: column;
  }
}
.page-template-template-about-us .video-section .column-layout .col:nth-of-type(2) {
  z-index: 9;
  pointer-events: none;
}
.page-template-template-about-us .staff-section .column-layout .col:nth-of-type(1) {
  width: 40%;
}
@media (max-width: 992px) {
  .page-template-template-about-us .staff-section .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.page-template-template-about-us .staff-section .column-layout .col:nth-of-type(2) {
  width: 60%;
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .page-template-template-about-us .staff-section .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding-left: 0;
    margin-top: 4rem;
  }
}
.page-template-template-about-us .staff-section h2 {
  text-align: center;
  width: 100%;
}
.page-template-template-about-us .staff-section img {
  width: 100%;
}
.page-template-template-about-us .testimonials-section img {
  width: 100%;
  height: auto;
  z-index: 1;
  position: relative;
  margin-bottom: -4rem;
}
.page-template-template-about-us .testimonials-section .cont {
  background: #5b809b;
  z-index: 9;
  position: relative;
  padding: 8rem 8rem 4rem;
}
@media (max-width: 992px) {
  .page-template-template-about-us .testimonials-section .cont {
    padding: 4rem 2rem;
  }
}
.page-template-template-about-us .testimonials-section .cont h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 4rem;
}
.page-template-template-about-us .testimonials-section .cont p {
  color: #fff;
}
.page-template-template-about-us .testimonials-section .cont .swiper-slide {
  display: flex;
  flex-direction: column;
}
.page-template-template-about-us .testimonials-section .cont .swiper-slide .name {
  align-self: flex-end;
  color: #fff;
}
@media (max-width: 992px) {
  .page-template-template-about-us .testimonials-section .cont .swiper-cont {
    padding-bottom: 4rem;
  }
}
.page-template-template-about-us .testimonials-section .cont .swiper-pagination {
  position: relative !important;
  bottom: auto;
  padding-left: 0;
  left: auto;
}
.page-template-template-about-us .testimonials-section .cont .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  transition: all 0.5s;
}
.page-template-template-about-us .testimonials-section .cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #fff;
}
.page-template-template-about-us .faqs-section .accordion-header p {
  color: #000;
}

.page-template-template-process .steps-sections {
  padding: 0;
}
.page-template-template-process .steps-sections .full:not(:last-of-type) {
  border-bottom: 1px solid #5b809b;
}
.page-template-template-process .steps-sections .full h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b809b;
  margin-bottom: 4rem;
}
.page-template-template-process .steps-sections .full h2 img {
  margin-right: 2rem;
  width: 50px;
  height: auto;
}
.page-template-template-process .steps-sections .full .column-layout .col:nth-of-type(1) {
  width: 40%;
}
@media (max-width: 992px) {
  .page-template-template-process .steps-sections .full .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.page-template-template-process .steps-sections .full .column-layout .col:nth-of-type(2) {
  width: 60%;
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .page-template-template-process .steps-sections .full .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding-left: 0;
    margin-top: 4rem;
  }
}
.page-template-template-process .steps-sections .full:nth-of-type(even) h2 {
  color: #d5b868;
}
.page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout {
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout {
    flex-direction: column;
  }
}
.page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout .col:nth-of-type(1) {
  width: 40%;
}
@media (max-width: 992px) {
  .page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout .col:nth-of-type(2) {
  width: 60%;
  padding-right: 4rem;
  padding-left: 0;
}
@media (max-width: 992px) {
  .page-template-template-process .steps-sections .full:nth-of-type(even) .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding-right: 0;
    margin-top: 4rem;
  }
}
.page-template-template-process .steps-sections .full:nth-of-type(even) .main-sub .main-img {
  align-self: flex-end;
}
.page-template-template-process .steps-sections .full:nth-of-type(even) .main-sub .sub-img {
  align-self: flex-start;
}