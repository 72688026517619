@at-root .logged-in .top {
	height: calc(100vh - 32px);

	.swiper-slide {
		height: calc(100vh - 32px);
	}
}

.home {
	.hero-section {
		.column-layout {
			width: 100%;

			.col {
				&:nth-of-type(1),
				&:nth-of-type(3) {
					width: 215px;
					flex: none;

					@media (max-width: bp(nav)) {
						width: 100%;
					}

					.btn {
						text-transform: uppercase;
						width: 100%;

						&:not(:last-of-type) {
							margin-bottom: $gap;
						}
					}
				}

				&:nth-of-type(2) {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
				}
			}
		}
	}

	.alternating-sections {
		.column-layout {
			align-items: stretch;
			justify-content: center;

			.col {
				&:nth-of-type(1) {
					width: 60%;
					z-index: 9;

					@media (max-width: bp(nav)) {
						width: calc(100%);
					}
				}

				&:nth-of-type(2) {
					width: 40%;
					padding-left: $padding--md;

					@media (max-width: bp(nav)) {
						width: 100%;
						padding: $padding--md $padding--sm 0;
					}
				}
			}

			h2 {
				color: color(accent, blue);
				width: 100%;
			}

			&:not(:last-of-type) {
				margin-bottom: $padding--lg;
			}

			&:nth-of-type(even) {
				flex-direction: row-reverse;

				@media (max-width: bp(nav)) {
					flex-direction: column;
				}

				.col {
					&:nth-of-type(1) {
					}

					&:nth-of-type(2) {
						padding-left: 0;
						padding-right: $padding--md;

						@media (max-width: bp(nav)) {
							padding: $padding--md $padding--sm 0;
						}
					}
				}
			}
		}

		&.btm {
			.column-layout {
				flex-direction: row;

				@media (max-width: bp(nav)) {
					flex-direction: column;
				}

				.col {
					&:nth-of-type(1) {
						width: 60%;
						z-index: 9;

						@media (max-width: bp(nav)) {
							width: calc(100%);
						}
					}

					&:nth-of-type(2) {
						width: 40%;
						padding-left: $padding--md;
						padding-right: 0;

						@media (max-width: bp(nav)) {
							width: 100%;
							padding: $padding--md $padding--sm 0;
						}
					}
				}

				&:nth-of-type(odd) {
					flex-direction: row-reverse;

					@media (max-width: bp(nav)) {
						flex-direction: column;
					}

					.col {
						&:nth-of-type(1) {
						}

						&:nth-of-type(2) {
							padding-left: 0;
							padding-right: $padding--md;

							@media (max-width: bp(nav)) {
								padding: $padding--md $padding--sm 0;
							}
						}
					}
				}
			}
		}
	}

	.specials-section {
		.swiper-section {
			margin-top: $padding--md;
		}
	}

	.floor-plans-section {
		background: color(white);
	}

	.gallery-section {
		padding-top: 0;

		.mosaic-section {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-column-gap: $gap;
			grid-row-gap: $gap;
			height: 500px;

			.card {
				@include imgCropper;

				&:nth-of-type(1) {
					grid-area: 1 / 1 / 4 / 2;
				}
				&:nth-of-type(2) {
					grid-area: 4 / 1 / 7 / 2;
				}
				&:nth-of-type(3) {
					grid-area: 1 / 2 / 3 / 3;
				}
				&:nth-of-type(4) {
					grid-area: 3 / 2 / 5 / 3;
				}
				&:nth-of-type(5) {
					grid-area: 5 / 2 / 7 / 3;
				}
				&:nth-of-type(6) {
					grid-area: 1 / 3 / 7 / 4;
				}
			}

			@media (max-width: bp(nav)) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(6, 1fr);
				grid-column-gap: $gap;
				grid-row-gap: $gap;

				.card {
					&:nth-of-type(1) {
						grid-area: 1 / 1 / 4 / 2;
					}
					&:nth-of-type(2) {
						grid-area: 4 / 1 / 7 / 2;
					}
					&:nth-of-type(3) {
						grid-area: 1 / 2 / 3 / 3;
					}
					&:nth-of-type(4) {
						grid-area: 3 / 2 / 5 / 3;
					}
					&:nth-of-type(5) {
						grid-area: 5 / 2 / 7 / 3;
					}
					&:nth-of-type(6) {
						display: none;
					}
				}
			}
		}
	}
}

.qd-card {
	background: color(white);
	text-decoration: none;
	font-family: $bodyFont;
	display: flex;
	flex-direction: column;
	border: 1px solid color(accent, blue);

	img {
		width: 100%;
	}

	.btm {
		padding: $padding--sm;

		p.title4 {
			text-align: center;
			color: color(accent, blue);
			font-family: $bodyFont;
			margin-bottom: $padding--sm;
		}

		.stats {
			border-bottom: 1px solid color(accent, blue);
			padding-bottom: $gap;
			margin-bottom: $gap;
			flex-direction: row !important;

			.col {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: color(black);
				text-transform: uppercase;
				line-height: 1.2;
				font-size: 1.4rem;

				span {
					color: color(accent, blue);
					font-weight: bold;
				}
			}
		}

		p {
			text-align: center;
			text-transform: uppercase;
			margin: 0;

			&:last-of-type {
				margin-bottom: $padding--sm;
			}
		}

		.arrow {
			content: "";
			width: 20px;
			height: 20px;
			flex: none;
			display: block;
			border-top: 1px solid color(accent);
			border-right: 1px solid color(accent);
			transform: rotate(45deg);
			position: relative;
			z-index: 99;
			margin: 0 auto;
			transition: all 1s;
		}
	}

	&:hover {
		.arrow {
			//transform: translate(10px, 0) rotate(45deg);
		}
	}
}
