// SOLO
.quickdeliveries-template-default {
	.intro-section {
		.stats {
			@include p-font-styles;
		}
	}

	.map-section {
		.column-layout {
			.col {
				width: 50%;
			}
		}
	}
}
