.video-bg {
	min-height: 500px;
	height: calc(100vh);
	max-height: calc(1080px);
	width: 100%;
	overflow: hidden;
	padding: 0;
	text-align: center;

	@media (max-width: bp(tablet)) {
		min-height: 100vh;
		height: 100vh;
		margin-bottom: 0;
		background-color: 0;
	}

	.cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 10;

		.inner {
			h1 {
				color: white;
			}
		}
	}

	iframe {
		width: 100vw;
		height: 56.25vw;
		min-height: 100vh;
		min-width: 177.77vh;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	video {
		position: relative;
		z-index: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: 1;
		overflow: hidden;
	}
}
