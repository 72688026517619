/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/

.swiper-section {
	position: relative;
	width: 100%;
	height: auto;

	.swiper-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.swiper-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.swiper-cont {
		overflow: hidden;
		position: relative;
	}

	.swiper-wrapper {
	}

	.swiper-button-prev {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: "";
			width: 14px;
			height: 30px;
			background-image: url("../images/slider-arrow.png");
			display: block;
			border: none !important;
		}
	}

	.swiper-button-next {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: "";
			width: 14px;
			height: 30px;
			background-image: url("../images/slider-arrow.png");
			transform: rotate(180deg);
			display: block;
			border: none !important;
		}
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: 0.5;
	}

	.swiper-pagination {
		width: 100%;
		position: relative;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: auto;
		margin-top: 0;
		left: auto;

		@media (max-width: 1450px) {
			left: 0px;
		}

		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
			margin: 0 5px;
			opacity: 1;

			background-color: rgba(255, 255, 255, 0);
			border: 1px solid color(accent, blue);
			transition: all 0.5s;

			&.swiper-pagination-bullet-active {
				opacity: 1;
				background-color: color(accent, blue);
			}
		}
	}

	&.inset {
		padding: 0 50px;

		&.preserve-horizontal {
			@media (max-width: bp(md)) {
				padding: 0 30px;

				.swiper-button-prev {
					width: 30px;
					height: 30px;
					top: calc((100% - 30px) / 2);
				}

				.swiper-button-next {
					width: 30px;
					height: 30px;
					top: calc((100% - 30px) / 2);
				}
			}
		}
	}

	&.grid-collapse {
		.swiper-button-next,
		.swiper-button-prev,
		.swiper-pagination {
			@media (min-width: $mobile) {
				display: none;
			}
		}

		.swiper-wrapper {
			@media (min-width: $mobile) {
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				justify-content: center;
			}
		}

		&.halves {
			.swiper-slide {
				width: calc((100% - #{$padding--lg}) / 2);

				@media (max-width: bp(xl)) {
					width: calc((100% - #{$padding--sm}) / 2);
					margin-bottom: $padding--sm;
				}

				@media (max-width: $mobile) {
					width: 100%;
				}

				&:not(:nth-of-type(even)) {
					margin-right: $padding--lg;

					@media (max-width: bp(xl)) {
						margin-right: $padding--sm;
					}

					@media (max-width: $mobile) {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		&.thirds {
			.swiper-slide {
				width: calc((100% - (#{$gap} * 4)) / 3);
				margin-bottom: #{$gap * 2};

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-bottom: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: calc(#{$gap} * 2);

					@media (max-width: bp(nav)) {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		&.quarters {
			.swiper-slide {
				width: calc((100% - calc(#{$gap} * 6)) / 4);

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				&:not(:nth-of-type(4n + 4)) {
					margin-right: calc(#{$gap} * 2);

					@media (max-width: bp(nav)) {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}
}

/****************************************

SITE SPECIFIC

****************************************/

.swiper-section {
	&.style-arrow {
		&.inset {
			padding: 0 100px;

			@media (max-width: bp(md)) {
				padding: 0;
			}
		}

		.swiper-button-prev {
			background: none;
			width: 100px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background-image: url("../images/arrow.png");
				background-position: center center;
				background-repeat: no-repeat;
				transform: rotate(180deg);
				display: block;
				border: none !important;
			}
		}

		.swiper-button-next {
			background: none;
			width: 100px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background-image: url("../images/arrow.png");
				background-position: center center;
				background-repeat: no-repeat;
				display: block;
				transform: none !important;
				border: none !important;
			}
		}
	}

	@media (max-width: bp(md)) {
		&:not(.preserve-horizontal) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			&.inset {
				padding: 0;
			}

			.swiper-cont {
				width: 100%;
				height: auto;
			}

			.swiper-button-prev {
				width: 30px;
				height: 30px;
				top: auto;
				right: auto;
				left: auto;
				bottom: auto;
				position: relative;
				margin: 10px 5px;
			}

			.swiper-button-next {
				width: 30px;
				height: 30px;
				top: auto;
				right: auto;
				left: auto;
				bottom: auto;
				position: relative;
				margin: 10px 5px;
			}
		}
	}
}
