/****************************************

FIXED HEADER

****************************************/
@at-root .logged-in header {
	top: 32px;

	@media (max-width: bp(sm)) {
		top: 45px;

		&.scroll {
			top: 0;
		}
	}
}

header {
	width: 100vw;
	z-index: 999;
	padding: $gap 0;
	position: fixed;
	transition: all 0.5s;

	@media (max-width: bp(nav)) {
		display: block;
		padding: 10px;

		&.scroll {
			min-height: 50px;
			padding: 10px;
		}
	}

	.logo {
		height: 85px;
		width: auto;
		display: block;
		//margin: 10px 0;

		@media (max-width: bp(nav)) {
			height: 50px;
		}

		img {
			height: 100%;
			width: auto;

			&.white {
				display: none;
			}
		}
	}

	.inner {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		position: relative;

		@media (max-width: bp(nav)) {
			justify-content: space-between;
			flex-wrap: wrap;
			flex-direction: row;
		}

		.col {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			right: 0;

			a {
				color: color(white);
				text-decoration: none;
				text-transform: uppercase;
				margin-right: $padding--sm;

				@media (max-width: bp(nav)) {
					display: none;
				}
			}
		}
	}

	.cont {
		@media (max-width: bp(nav)) {
			width: 100%;
			margin: 0;
		}
	}

	.menu-toggle {
		width: 50px;
		height: 50px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		background: color(accent);
		z-index: 99999;
		cursor: pointer;

		transition: all 0.5s;

		.line {
			border-bottom: 2px solid color(white);
			width: 30px;
			height: 1px;
			position: relative;
			display: block;
			transition: all 0.5s;
		}
	}

	&.active {
		.menu-toggle {
			justify-content: center;

			.line {
				&:nth-of-type(3) {
					opacity: 0;
				}

				&:nth-of-type(1) {
					transform: rotate(45deg);
				}

				&:nth-of-type(2) {
					transform: rotate(-45deg) translate(1px, -2px);
				}
			}
		}

		.inner .col a {
			color: color(black);
			z-index: 9;
		}
	}
}

body.scroll {
	//padding-top: 107px;

	@media (max-width: bp(nav)) {
		padding-top: 0;
	}

	header {
		background: color(accent);

		.logo {
			.color {
				display: none;
			}

			.white {
				display: block;
			}
		}

		.menu-toggle {
			background: color(white);

			.line {
				border-bottom: 2px solid color(accent);
			}
		}
	}
}

#main-nav {
	ul {
		li {
			position: relative;

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&.menu-item-has-children {
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					@media (max-width: bp(nav)) {
						justify-content: flex-start;
					}

					&:after {
						//	content: "";
						width: 7px;
						height: 7px;
						display: block;
						margin-left: 10px;
						border-right: 1px solid color(accent);
						border-top: 1px solid color(accent);
						transform: translate(0, -3px) rotate(135deg);
						transition: transform $animationDuration;
					}
				}
			}

			&.current-menu-parent {
				> a {
					text-decoration: underline;
				}
			}

			&.current-menu-item {
				a {
					color: color(accent);
				}
			}
		}

		a {
			font-size: fontsize(nav);
			line-height: 1.2;
			text-decoration: none;
			transition: color 0.5s;

			&:hover {
				color: color(accent);
			}
		}
	}
}

.header-spacing {
	height: 77px;
	width: 100%;
}
