/****************************************

CONTAINERS

****************************************/

.cont--lg {
	max-width: 1400px;
	margin: 0 auto;

	@media (max-width: 1450px) {
		padding: 0 $padding--sm;
	}
}

.cont {
	max-width: 1200px;
	margin: 0 auto;

	@media (max-width: bp(xl)) {
		padding: 0 $padding--sm;
	}
}

.cont--sm {
	max-width: 925px;
	margin: 0 auto;

	@media (max-width: bp(tablet)) {
		padding: 0 $padding--sm;
	}
}

.cont--lg,
.cont,
.cont--sm {
	&.mc {
		@media (max-width: bp(nav)) {
			padding: 0;
		}
	}
}

/****************************************

LAYOUTS

****************************************/

.column-layout {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile) {
		flex-direction: column;
	}

	.col {
		// defaults to 50/50
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: calc((100% - #{$padding--lg}) / 2);

		@media (max-width: bp(xl)) {
			width: calc((100% - #{$padding--sm}) / 2);
		}

		@media (max-width: $mobile) {
			width: 100%;
		}

		&--two-thirds {
			width: calc((100% * 2 / 3) - #{$padding--lg});

			@media (max-width: bp(xl)) {
				width: calc((100% * 2 / 3) - #{$padding--sm});
			}

			@media (max-width: $mobile) {
				width: 100%;
			}
		}

		&--third {
			width: calc((100%) * 1 / 3);

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	&.sm-gap {
		.col {
			width: calc((100% - #{$padding--sm}) / 2);

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	&.sticky-right {
		align-items: flex-start;

		.col {
			min-height: calc(100vh - 32px - #{$padding--sm} - #{$padding--sm});

			&:first-of-type {
				min-height: 200vh;
			}

			&:last-of-type {
				position: sticky;
				top: calc(32px + #{$padding--sm}); //change to nav height
			}
		}
	}
}

.card-layout {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;

	&.halves {
		.card {
			width: calc((100% - #{$padding--lg}) / 2);
			margin-bottom: $padding--lg;

			@media (max-width: bp(xl)) {
				width: calc((100% - #{$padding--sm}) / 2);
				margin-bottom: $padding--sm;
			}

			@media (max-width: $mobile) {
				width: 100%;
			}

			&:not(:nth-of-type(even)) {
				margin-right: $padding--lg;

				@media (max-width: bp(xl)) {
					margin-right: $padding--sm;
				}

				@media (max-width: $mobile) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.thirds {
		.card {
			width: calc((100% - (#{$gap} * 4)) / 3);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:nth-of-type(3n + 3)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.quarters {
		.card {
			width: calc((100% - calc(#{$gap} * 6)) / 4);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:nth-of-type(4n + 4)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.fifths {
		.card {
			width: calc((100% - calc(#{$gap} * 8)) / 5);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:nth-of-type(5n + 6)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.grid-layout {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: #{$padding--sm};

	@media (max-width: bp(xl)) {
		grid-template-columns: repeat(4, 1fr) !important;
	}

	@media (max-width: bp(tablet)) {
		grid-template-columns: repeat(3, 1fr) !important;
	}

	@media (max-width: bp(nav)) {
		grid-template-columns: repeat(2, 1fr) !important;
	}

	.card {
		padding: $padding--xs;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		text-align: center;
		position: relative;
		cursor: pointer;
		transition: all 0.5s;
	}

	.desc {
		display: none;
	}
}

.wrap-layout {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.col,
	.card,
	.field,
	.group {
		width: calc((100% - #{$padding--sm}) / 2);
		@include elementSpacing;

		@media (max-width: bp(xl)) {
			width: calc((100% - #{$padding--sm}) / 2);
		}

		@media (max-width: $mobile) {
			width: 100%;
		}
	}

	.group {
		.card,
		.field {
			width: 100%;
			margin: 0 0 5px 0;
		}
	}
}
